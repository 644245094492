import styled from 'styled-components';
import img from '../../../images/components/banner/mobile_img.jpg';
import bgMobile from '../../../images/components/banner/deco_bg.svg';
import { colorsBar, media } from '../../../ui/common';

export const WrapPopup = styled.div`
  max-width: 320px;
  position: relative;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 11px 32px -5px rgba(54, 61, 77, 0.15);
  background-color: ${colorsBar.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: 300ms all;
  text-align: center;
  z-index: ${(props) => (props.isOpen ? '8888888' : '-1')};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  border-radius: 20px;
  overflow: hidden;

  ${media.tablet`
    max-width: 530px;
    display: flex;
  `};
`;

export const Image = styled.div`
  background-image: url(${img});
  background-size: 100%;
  min-height: 320px;
  ${media.tablet`
    min-width: 292px;
    background-position-x: -15px;
  `};
`;

export const Info = styled.div`
  background-image: url(${bgMobile});
  min-height: 242px;
  padding: 27px 13px 13px;

  ${media.tablet`
    order: -1;
    min-width: 246px;
    background-size: 100%;
  `};

  h3,
  h4,
  h5 {
    color: ${colorsBar.white};
    margin: 0;
  }

  h4 {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 5px;
    font-family: 'Neue Machina', sans-serif;

    ${media.laptop`
      margin-bottom: 20px;
    `};
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;

    ${media.tablet`
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 22px;
    `};
  }

  h3 {
    font-weight: 800;
    font-size: 45px;
    line-height: 54px;
    margin-bottom: 13px;

    ${media.tablet`
      margin-bottom: 42px;
      font-size: 40px;
      line-height: 48px;
    `};
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background-color: #fdc1aa;
    padding: 7px 30px;
    color: ${colorsBar.white};
    border-radius: 7px;
    display: block;
    margin-bottom: 12px;
    min-width: 117px;
    box-shadow: none;
    border: none;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    ${media.tablet`
      min-width: 152px;
    `}
  }
`;
