import React from 'react';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import theme from './src/ui/theme';
import messages from './src/i18n/messages';
import SEO from './src/components/seo';
import LayoutWrap from './src/components/Layout/LayoutWrap';

const DEFAULT_MUI_PICKERS_LOCALE = enLocale;
const getMuiPickersLocale = (locale) => {
  if (locale === 'ru') return ruLocale;
  // TODO Add other locales

  return DEFAULT_MUI_PICKERS_LOCALE;
};

/* eslint-disable react/prop-types, react/destructuring-assignment */
export default function wrapPageElement({ element, props }) {
  const { locale } = props.pageContext;

  return (
    <MuiThemeProvider theme={theme}>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        defaultLocale={locale}
      >
        <LayoutWrap>
          <SEO pathName={props.location.pathname} />
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={getMuiPickersLocale(locale)}
          >
            {element}
          </MuiPickersUtilsProvider>
        </LayoutWrap>
      </IntlProvider>
    </MuiThemeProvider>
  );
}
