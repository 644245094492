const getLoadableEntityActionTypes = (prefix) => ({
  Fetch: `${prefix}.Fetch`,
  Success: `${prefix}.Success`,
  Fail: `${prefix}.Fail`,
  MarkAsStale: `${prefix}MarkAsStale`,
});

const ActionType = {
  Countries: {
    Add: 'Countries.Add',
    Pick: 'Countries.Pick',
  },
  Basket: {
    Visible: 'Basket.Visible',
    AddItem: 'Basket.AddItem',
    RemoveItem: 'Basket.RemoveItem',
    Clear: 'Basket.Clear',
    UpdateCount: 'Basket.UpdateCount',
    UpdateCartridge: 'Basket.UpdateCartridge',
    UpdatePromo: 'Basket.UpdatePromo',
    UpdatePriceItems: 'Basket.UpdatePriceItems',
    UpdateType: 'Basket.UpdateType',
  },
  Items: {
    Add: 'Items.Add',
    Clear: 'Items.Clear',
  },
  States: {
    Add: 'States.Add',
  },
  Cities: {
    Add: 'Cities.Add',
  },
  Checkout: {
    MutateShippingCity: 'Checkout.MutateShippingCity',
    GetShippingMethods: 'Checkout.GetShippingMethods',
    GetPaymentMethods: 'Checkout.GetPaymentMethods',
    GetPackages: 'Checkout.GetPackages',
    SetError: 'Checkout.SetError',
  },
  Payment: {
    SetData: 'Payment.SetData',
    SetId: 'Payment.SetId',
  },
  Locale: {
    Set: 'Locale.Set',
  },
  Preferences: {
    PreferDeviceInsteadOfPackage: 'Preferences.PreferDeviceInsteadOfPackage',
    PreferPackageInsteadOfDevice: 'Preferences.PreferPackageInsteadOfDevice',
  },
  IpRegistry: getLoadableEntityActionTypes('IpRegistry'),
  Initialization: {
    Start: 'Initialization.Start',
    Success: 'Initialization.Success',
    Fail: 'Initialization.Fail',
  },
  WarrantyRegistrationForm: {
    SetModel: 'WarrantyRegistrationForm.SetModel',
    SetName: 'WarrantyRegistrationForm.SetName',
    SetEmail: 'WarrantyRegistrationForm.SetEmail',
    SetPhone: 'WarrantyRegistrationForm.SetPhone',
    SetDateOfBirth: 'WarrantyRegistrationForm.SetDateOfBirth',
    ToggleChecked: 'WarrantyRegistrationForm.ToggleChecked',
    SetHasBeenSubmittedAtLeastOnce:
      'WarrantyRegistrationForm.SetHasBeenSubmittedAtLeastOnce',
    SetInvalidField: 'WarrantyRegistrationForm.SetInvalidField',
    SetStep: 'WarrantyRegistrationForm.SetStep',
  },
};

export default Object.freeze(ActionType);
