import React from 'react';
import PropTypes from 'prop-types';
import DotLoader from 'react-spinners/DotLoader';
import BackgroundImage from './background-image';

function PreviewPage({ redir }) {
  return redir === '/' ? <BackgroundImage /> : <DotLoader color="green" />;
}

PreviewPage.propTypes = {
  redir: PropTypes.string.isRequired,
};

export default PreviewPage;
