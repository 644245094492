import ActionType from '../action-type';

export default function preferences(
  // eslint-disable-next-line default-param-last
  state = { deviceInsteadOfPackage: true },
  action
) {
  switch (action.type) {
    case ActionType.Preferences.PreferDeviceInsteadOfPackage:
      return { ...state, deviceInsteadOfPackage: true };
    case ActionType.Preferences.PreferPackageInsteadOfDevice:
      return { ...state, deviceInsteadOfPackage: false };
    default:
      return state;
  }
}
