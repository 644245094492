import ActionType from '../action-type';

export default (
  // eslint-disable-next-line default-param-last
  state = {
    shippingList: [],
    paymentList: [],
    packages: [],
    error: { code: 0, text: '' },
  },
  action
) => {
  switch (action.type) {
    case ActionType.Checkout.GetShippingMethods:
      return {
        ...state,
        shippingList: action.payload,
      };
    case ActionType.Checkout.GetPaymentMethods:
      return {
        ...state,
        paymentList: action.payload,
      };
    case ActionType.Checkout.GetPackages:
      return {
        ...state,
        packages: action.payload,
      };
    case ActionType.Checkout.SetError:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
