import 'normalize.css';
import 'swiper/css/swiper.css';
import './src/scss/fonts.scss';
import './src/scss/global.scss';
import './src/scss/special.scss';

export const onClientEntry = () => {
  if (!window.fetch) {
    // eslint-disable-next-line no-unused-expressions
    import('whatwg-fetch');
  }
};

export const onRouteUpdate = ({ location }) => {
  const { hash } = location;
  if (!hash) return;

  setTimeout(() => {
    const node = document.querySelector(hash);
    if (!node) return;

    node.scrollIntoView({ block: 'start' });
  }, 0);
};

export { default as wrapRootElement } from './wrap-root-element';
export { default as wrapPageElement } from './wrap-page-element';
