module.exports = {
  'faq.title': `
    Avez-vous des questions ?
  `,

  'faq.text': `
   Lisez les réponses aux questions souvent posées
   ou posez votre propre question.
  `,

  'faq.question1': 'Comment fonctionne Evapolar ?',
  'faq.answer1': `
    Evapolar est un appareil personnel portable qui non seulement rafraîchit mais aussi humidifie et purifie l'air qui vous entoure. Le principe de fonctionnement est très simple : il suffit de remplir le réservoir d'eau et de brancher Evapolar. Au bout de 2 à 3 minutes, la cartouche Evapolar absorbera une énorme quantité d'eau. L'eau se répand ensuite uniformément sur la surface des plaquettes de rafraîchissement. Lorsque l'air circule à travers les plaquettes, l'eau s'évapore et l'air se rafraîchit tout en se gorgeant d'eau. Le ventilateur intégré aide l'air frais à se répandre sur une surface de 3 à 4 mètres carrés autour de vous. La commande de réglage de la puissance vous permet d'ajuster l'effet de rafraîchissement, créant ainsi les conditions thermiques les plus confortables pour vous.
  `,

  'faq.question2':
    "Quelle est la différence entre Evapolar et d'autres dispositifs de refroidissement ?",
  'faq.answer2': `
    Evapolar surpasse d'autres dispositifs de refroidissement dans beaucoup d'aspects.
    Premièrement, Evapolar est un dispositif personnel.
    Il peut vous aider à créer votre propre microclimat le plus confortable
    sans envahir l'espace personnel des gens autour de vous.
    Deuxièmement, nous appelons Evapolar un " climatiseur d'air " plutôt qu'un
    " refroidisseur d'air " grâce à son efficacité en comparaison avec climatiseurs
    " traditionnels ". Evapolar reste dans la plage de prix des dispositifs de table économiques,ne nécessite aucuns frais d'installation et est
     prêt à l'emploi dès la sortie de la boîte.
  `,
  'faq.answer2.2': `
    Troisièmement, Evapolar refroidit l'air en évaporant l'eau,
    ce qui rend le dispositif un humidificateur efficace.
    Vous n'avez pas besoin d'acheter séparément un climatiseur, un humidificateur
    ou un purificateur. Notre technologie regroupe toutes les trois fonctions
    en un seul dispositif. Voilà comment Evapolar crée le soi-disant
    “microclimat personnel”. Enfin, et ce n'est pas le moins important,
    Evapolar utilise 100 fois moins d'énergie que les systèmes de climatisation
    standard - seulement 10W - ce qui aura clairement
    un effet positif sur votre facture d'électricité
  `,

  'faq.question3': 'Evapolar, fonctionne-t-il sans alimentation électrique ?',
  'faq.answer3': `
    Afin d'utiliser Evapolar, vous devez le brancher sur une source d'alimentation en courant électrique.
    Mais il n'est pas nécessaire que ce soit une prise de courant.
    Vous pouvez brancher Evapolar sur un chargeur portable,
    le port USB de votre voiture ou ordinateur portable, ou sur une batterie solaire.
    La seule condition est que l'alimentation doit avoir
    une sortie de 5V et 2A.
  `,

  'faq.question4':
    "Dans quelle mesure l'Evapolar réduit-il la température et quelle superficie peut-il rafraîchir ?",
  'faq.answer4': `
    La baisse maximale de température est de 15°C (59°F). Il convient de mentionner que le changement de température dépend des conditions climatiques extérieures et de la puissance du dispositif. Dans les régions très humides, la différence peut aller jusqu'à degrés 41°F (5°C), dans les régions sèches et chaudes, elle peut atteindre 59°F (15°C). Nous avons testé l'appareil dans le monde entier et sommes arrivés à la conclusion que chaque individu a une perception différente de ce qu'est une baisse de température agréable. Votre propre corps sera toujours votre meilleur indicateur, car ses besoins varient considérablement en fonction de votre climat d'origine et de la saison en cours.
  `,
  'faq.answer4.2': `
    Les climatiseurs classiques ne tiennent pas compte de ce facteur. Singapour illustre parfaitement cette situation : vous pouvez y trouver le confort nécessaire avec une perte de température de 41°F (5°C), alors qu'une baisse de 10°C (50°F) est désagréable pour votre peau.
  `,
  'faq.answer4.3': `
    Evapolar peut refroidir une surface de 3 à 4 mètres carrés. 
    Evapolar est un appareil personnel développé spécifiquement pour les petites surfaces. 
    Il vous aidera à créer les meilleures conditions de confort possibles sur votre lieu de travail ou dans votre espace de loisirs.
  `,

  'faq.question5':
    'Evapolar, nécessite-t-il une installation ou une maintenance supplémentaire ? Que dois-je faire en cas de son dysfonctionnement ?',
  'faq.answer5': `
    Evapolar est facile à utiliser et prêt de fonctionner
    dès la sortie de la boîte. Ce que vous devez faire, c'est de remplir
    le réservoir d'eau et de brancher le dispositif. Il faut remplir le réservoir d'eau
    toutes les 6-8 heures, au fur et à mesure que l'eau s'évapore.
    Vous pouvez en savoir plus sur l'utilisation d'Evapolar dans le manuel ci-joint.
    Au total, il est seulement important d'empêcher le dispositif
    de se retourner et d'essuyer la chambre d'opération une fois par mois.
  `,
  'faq.answer5.2': `
    En cas de dysfonctionnement, veuillez contacter notre
    service de soutien sur support@evapolar.com ou votre distributeur local
    (si vous n'avez pas acheté votre dispositif sur notre site web).
  `,
};
