import { errorCodes } from '../../utils/api/discount';
import { isItemLeftCheck } from '../../utils/api/basket';

const selectBasket = (state) => state.basket;

// TODO Use it or delete it
export const selectDefaultCurrency = (state) =>
  selectBasket(state).defaultCurrency;

export const selectItems = (state) => selectBasket(state).list;

export const selectItemsCount = (state) => selectItems(state).length;

export const selectPromoCode = (state) => selectBasket(state).promoCode;

const selectDiscountErrorCode = (state) => selectBasket(state).discountError;
const prefix = 'checkout.discount.errors.';
const errorText = {
  [errorCodes.DISCOUNT_OK]: '',
  [errorCodes.DISCOUNT_NOT_FOUND]: `${prefix}not_found`,
  [errorCodes.DISCOUNT_EARLY]: `${prefix}early`,
  [errorCodes.DISCOUNT_EXPIRED]: `${prefix}expired`,
  [errorCodes.DISCOUNT_NOT_AVAILABLE]: `${prefix}not_available`,
  [errorCodes.DISCOUNT_NO_USAGES]: `${prefix}no_usages`,
  [errorCodes.DISCOUNT_MIN_ORDER]: `${prefix}min_order`,
  [errorCodes.DISCOUNT_VARIANTS]: `${prefix}variants`,
  [errorCodes.DISCOUNT_UNKNOWN]: `${prefix}unknown`,
};
export const selectDiscountErrorText = (state) => {
  const code = selectDiscountErrorCode(state);

  return errorText[code] || '';
};

export const selectIsInvalidBasket = (state) =>
  selectItems(state).some((v) => isItemLeftCheck(v));
