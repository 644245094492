import { useDispatch } from 'react-redux';

import ActionType from '../store/action-type';
import createHandleBasketUpdateThunk from '../store/thunks/createHandleBasketUpdateThunk';
import createRemoveItemThunk from '../store/thunks/createRemoveItemThunk';
import * as dataLayer from '../utils/datalayer/dataLayer';
import { prepareItem } from '../utils/api/basket';

const useBasket = () => {
  const dispatch = useDispatch();

  return {
    clearItems: async () => {
      dispatch({ type: ActionType.Basket.Clear });
      await dispatch(createHandleBasketUpdateThunk());
    },
    removeItem: async (payload) => {
      await dispatch(createRemoveItemThunk(payload));
      await dispatch(createHandleBasketUpdateThunk());
    },
    setItemCount: async (payload) => {
      dispatch({ type: ActionType.Basket.UpdateCount, payload });
      await dispatch(createHandleBasketUpdateThunk());
    },
    setItemCartridgeState: async (payload) => {
      dispatch({ type: ActionType.Basket.UpdateCartridge, payload });
      await dispatch(createHandleBasketUpdateThunk());
    },
    addItem: async (item, showBasket) => {
      dataLayer.addToCart(item);
      if (showBasket) {
        dispatch({ type: ActionType.Basket.Visible, payload: true });
      }
      dispatch({
        type: ActionType.Basket.AddItem,
        payload: prepareItem(item),
      });
      await dispatch(createHandleBasketUpdateThunk());
    },
    setPromoCode: async (payload) => {
      dispatch({ type: ActionType.Basket.UpdatePromo, payload });
      await dispatch(createHandleBasketUpdateThunk());
    },
  };
};

export default useBasket;
