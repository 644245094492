import isEmpty from 'lodash/isEmpty';
import { isCartridge, isDevice, isPackage } from '../slug-checks';
import { getStore } from '../../store';

// TODO Use selector instead
export const getItems = () => getStore().getState().items.list;

export const getItemColorList = (item) => {
  if (!item.product && !item.product.schemas) {
    return [];
  }
  const colorsList = item.product.schemas.find(
    (schema) => schema.short_name === 'color'
  );
  return colorsList === undefined ? [] : colorsList.choices;
};

export const getItemColorTitleByShort = (item, colorShort) => {
  const list = getItemColorList(item);

  if (list) {
    const color = list.find((v) => v.short_name === colorShort);

    if (color) return color.title;
  }

  return '';
};

export const getItemName = (item) => item.product && item.product.name;

export const getItemCategory = (item) => item.product && item.product.title;

export const getItemPlug = (item) => item.attributes && item.attributes.plug;

export const getItemPrice = (item) => {
  try {
    if (process.env.GATSBY_SHOW_TAX_PRICES === 'true') {
      return item.country_prices[0].taxed_price.amount;
    }
    return item.country_prices[0].price.amount;
  } catch (e) {
    return null;
  }
};

export const getItemColorAttribute = (item) =>
  (item.attributes && item.attributes.color) || '';

export const getItemColorName = (item) => {
  const color = getItemColorAttribute(item);
  return getItemColorTitleByShort(item, color);
};

export const hasAttributes = (item) =>
  item.attributes !== null && !isEmpty(item.attributes);

// TODO Use camelCase: `country_prices` -> `countryPrices`
export const getItemPriceCurrency = (item) =>
  // eslint-disable-next-line camelcase
  item?.country_prices?.[0]?.price?.currency || 'USD';

export const getItemSlug = (item) =>
  (item && item.product && item.product.slug) || '';

export const getItemProductId = (item) =>
  (item && item.product && item.product.id) || 0;

const getItemImageData = (item) => {
  if (item && item.gallery && item.gallery[0] && item.gallery[0].image)
    return item.gallery[0].image;

  return null;
};

export const getItemPreviewImage = (item) => {
  const data = getItemImageData(item);
  if (data) return data.small_crop;

  return null;
};

export const getItemColorImage = (item, colorName) => {
  const items = getItems();

  const { product } = item;

  const founded = items.find(
    (value) =>
      value.product.name === product.name &&
      value.attributes.color === colorName
  );

  if (founded) return founded.gallery[0].image;

  return null;
};

export const getItemImageByColor = (item, colorName, typeImg) => {
  if (typeof colorName === 'string') {
    const image = getItemColorImage(item, colorName);

    if (image) return image[typeImg];
  }

  const data = getItemImageData(item);

  if (data) return data[typeImg];

  return null;
};

export const getItemMediumImage = (item, colorName) =>
  getItemImageByColor(item, colorName, 'big_thumbnail');

export const getItemBigImage = (item, colorName) =>
  getItemImageByColor(item, colorName, 'big_crop');

export const getItemFullImage = (item, colorName) =>
  getItemImageByColor(item, colorName, 'full_size');

export const isItemDevice = (item) =>
  item && item.product && item.product.slug && isDevice(item.product.slug);

export const isItemPackage = (item) =>
  item && item.product && item.product.slug && isPackage(item.product.slug);

export const isItemCartridge = (item) =>
  item && item.product && item.product.slug && isCartridge(item.product.slug);

export const isItemAccessory = (item) =>
  !isItemDevice(item) && !isItemPackage(item);

export const getItemRemainders = (item) =>
  item.remainders && item.remainders[0];

export const getItemRemaindersQuantity = (item) => {
  const remainders = getItemRemainders(item);

  if (remainders) return remainders.available_quantity;

  return 0;
};

export const getItemPackage = (item) => {
  if (item.package && item.package[0]) {
    const id = item.package[0];
    const items = getItems();

    return items.find((value) => value.id === id);
  }

  return null;
};

export const getItemDeviceForPackage = (item) => {
  const { parts } = item;
  if (parts && parts.length === 2) {
    const items = getItems();
    const second = items.find((value) => value.id === parts[1]);
    if (isItemDevice(second)) {
      return second;
    }
    return items.find((value) => value.id === parts[0]);
  }
  return null;
};

export const getItemSKU = (item) => item.sku;

export const getItemDescription = (item) =>
  (item && item.product && item.product.description) || '';

export const isItemLimited = (item) => {
  const color = getItemColorAttribute(item);

  if (color) {
    const choices = getItemColorList(item);

    if (choices) {
      const choice = choices.find((v) => v.short_name === color);

      if (choice) {
        return choice.is_limited;
      }
    }
  }

  return false;
};

export const getItemAdditionalGoods = (item) => item.additional_goods || [];

export const getItemOrderingNumber = (item) =>
  item && item.product && item.product.ordering_number;
