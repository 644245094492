module.exports = {
  'loading.order': `
    Preparar orden
  `,
  'loading.payment': `
    Preparar el pago
  `,
  'loading.update_order': `
    Actualizar orden
  `,
};
