import has from 'lodash/has';
import {
  getItemSlug,
  getItemPrice,
  getItemPriceCurrency,
  getItemProductId,
  getItemName,
} from '../api/items';

function itemsToProducts(items) {
  const products = {};
  items.forEach((item) => {
    const name = getItemName(item);
    if (has(products, name)) {
      return;
    }
    products[name] = {
      id: String(getItemProductId(item)),
      fullName: getItemSlug(item),
      alias: name,
      currency: getItemPriceCurrency(item),
      finalCost: +getItemPrice(item),
      oldPrice: +getItemPrice(item),
    };
  });
  return products;
}

export default itemsToProducts;
