import has from 'lodash/has';
import ActionType from '../action-type';
import {
  getCartItemAlternateId,
  toggleDualItem,
} from '../../domain/CartItems/cart-items-helper';

function cartMakeVisible(state, visible) {
  return { ...state, visible };
}

function cartAddItem(state, itemToAdd) {
  const sameItem = state.list.find((value) => value.id === itemToAdd.id);

  return {
    ...state,
    list: sameItem
      ? state.list.map((item) =>
          item === sameItem ? { ...item, count: item.count + 1 } : item
        )
      : [...state.list, itemToAdd],
  };
}

function cartRemoveItem(state, item) {
  const items = state.list.filter((value) => item.id !== value.id);
  return { ...state, list: items };
}

function cartClear(state) {
  return { ...state, list: [] };
}

function cartUpdateCount(state, data) {
  const { id, count } = data;
  const list = state.list.map((value) => {
    if (value.id === id) {
      return { ...value, count };
    }
    return value;
  });
  return { ...state, list };
}

function cartItemAlternateState(state, data) {
  const { id } = data;
  const foundItem = state.list.find((value) => value.id === id);
  if (!foundItem) {
    return state;
  }
  const alterId = getCartItemAlternateId(foundItem);
  const foundAlterItem = state.list.find((value) => value.id === alterId);

  if (!foundAlterItem) {
    toggleDualItem(foundItem);
    const newItem = toggleDualItem(foundItem);
    const list = state.list.map((value) => {
      if (value.id === id) {
        return newItem;
      }
      return value;
    });
    return { ...state, list };
  }
  const list = [];
  state.list.forEach((item) => {
    if (item.id === id) {
      return;
    }
    if (item.id === alterId) {
      list.push({ ...item, count: item.count + foundItem.count });
      return;
    }
    list.push(item);
  });
  return { ...state, list };
}

function cartUpdatePromoCode(state, promoCode) {
  return { ...state, promoCode };
}

function cartUpdatePriceItems(state, priceData) {
  const { subtotal, shipping, taxes, discount, oldPrice, total } = priceData;
  return {
    ...state,
    subtotal,
    shipping,
    taxes,
    discount,
    oldPrice,
    total,
  };
}

function cartUpdateType(state, cartData) {
  return {
    ...state,
    ...cartData,
  };
}

const processors = {
  [ActionType.Basket.Visible]: cartMakeVisible,
  [ActionType.Basket.AddItem]: cartAddItem,
  [ActionType.Basket.RemoveItem]: cartRemoveItem,
  [ActionType.Basket.Clear]: cartClear,
  [ActionType.Basket.UpdateCount]: cartUpdateCount,
  [ActionType.Basket.UpdateCartridge]: cartItemAlternateState,
  [ActionType.Basket.UpdatePromo]: cartUpdatePromoCode,
  [ActionType.Basket.UpdatePriceItems]: cartUpdatePriceItems,
  // TODO Why is this so ambiguous? Refactor
  [ActionType.Basket.UpdateType]: cartUpdateType,
};

export default function basket(
  // eslint-disable-next-line default-param-last
  state = {
    list: [],
    visible: false,
    promoCode: '',
    promoCodeFinal: '',
    promoCodeEntered: '',
    subtotal: null,
    shipping: null,
    taxes: null,
    cartSubtotal: null,
    trueSubtotal: null,
    cartDiscount: null,
    discount: null,
    total: null,
    oldPrice: null,
    discountError: null,
    defaultCurrency: 'USD',
  },
  action
) {
  if (has(processors, action.type))
    return processors[action.type](state, action.payload);
  return state;
}
