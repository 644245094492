import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';
import locales from '../i18n/locales.json';

const TITLE_POSTFIX_BY_LOCALE = new Map([
  ['en-AU', ' | Australia - Evapolar'],
  ['de', ' | Germany - Evapolar'],
]);
const DEFAULT_TITLE_POSTFIX = '';

const DESCRIPTION_POSTFIX_BY_LOCALE = new Map([
  ['en-AU', '. Australia'],
  ['de', '. Germany'],
]);
const DEFAULT_DESCRIPTION_POSTFIX = '';

const pathsByLocale = Object.entries(locales).reduce(
  (accumulator, v) => accumulator.set(v[0], v[1].path),
  new Map()
);
const localesPaths = Object.values(locales)
  .map((v) => v.path)
  .sort((v1, v2) => v2.length - v1.length);

const getLinks = () => {
  const linksData = [];

  // https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  if (typeof window !== 'undefined') {
    const { location } = window;
    const [, path] = location.href.split(location.origin);
    const currentLocale = localesPaths.find((v) => path.startsWith(`/${v}`));
    let pathWithoutLocale = path;
    if (currentLocale) {
      [, pathWithoutLocale] = path.split(`/${currentLocale}`);
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [locale, localePath] of pathsByLocale.entries()) {
      linksData.push({
        hreflang: locale,
        href: `${location.origin}/${localePath}${pathWithoutLocale}`,
      });
    }
    linksData.push({
      hreflang: 'x-default',
      href: `${location.origin}${pathWithoutLocale}`,
    });
  }

  return linksData;
};

const wrapped = {
  '': 'index',
};

const CHILL_THE_OCEAN_PATH_NAME_WITHOUT_LOCALE = 'chill-the-ocean';

function isHiddenFromRobots(pathNameWithoutLocale) {
  return (
    pathNameWithoutLocale === 'family-warranty' ||
    pathNameWithoutLocale === 'warranty-club' ||
    pathNameWithoutLocale === 'family-club' ||
    pathNameWithoutLocale === 'samokat'
  );
}

function isCustomBackground(pathNameWithoutLocale) {
  return (
    pathNameWithoutLocale === 'family-warranty' ||
    pathNameWithoutLocale === 'warranty-club' ||
    pathNameWithoutLocale === 'family-club'
  );
}

// https://stackoverflow.com/a/67304579
const getCanonicalLink = (input) => {
  const url = new URL(`${process.env.GATSBY_BASE_URL}${input}`);
  url.hash = '';
  url.search = '';

  return url.toString();
};

function SEO({ pathName }) {
  const intl = useIntl();

  const { locale } = intl;
  const pathNameWithoutLocale = pathName
    .replace(`/${locale}`, '')
    .replace(/\//g, '');

  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitlePrefix
            defaultTitle
            defaultDescription
            siteName
          }
        }
      }
    `
  );

  let title = siteMetadata.defaultTitle;
  let desc = siteMetadata.defaultDescription;
  let ogImg;

  if (intl) {
    const wrappedType = wrapped[pathNameWithoutLocale] || pathNameWithoutLocale;

    title = intl.formatMessage({
      id: `meta.${wrappedType}.title`,
      defaultMessage: siteMetadata.defaultTitle,
    });
    desc = intl.formatMessage({
      id: `meta.${wrappedType}.desc`,
      defaultMessage: siteMetadata.defaultDescription,
    });
    ogImg =
      pathNameWithoutLocale === CHILL_THE_OCEAN_PATH_NAME_WITHOUT_LOCALE
        ? intl.formatMessage({ id: `meta.${wrappedType}.og-img` })
        : null;
  }

  const linksData = getLinks();

  title += TITLE_POSTFIX_BY_LOCALE.get(locale) ?? DEFAULT_TITLE_POSTFIX;
  desc +=
    DESCRIPTION_POSTFIX_BY_LOCALE.get(locale) ?? DEFAULT_DESCRIPTION_POSTFIX;

  return (
    <Helmet defer={false} htmlAttributes={{ lang: locale }} title={title}>
      <meta name="description" content={desc} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      {pathNameWithoutLocale === CHILL_THE_OCEAN_PATH_NAME_WITHOUT_LOCALE && (
        <meta property="og:image" content={ogImg} />
      )}
      {pathNameWithoutLocale === CHILL_THE_OCEAN_PATH_NAME_WITHOUT_LOCALE && (
        <meta name="twitter:image" content={ogImg} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@evapolar_com" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:domain" content="evapolar.com" />
      {isHiddenFromRobots(pathNameWithoutLocale) ? (
        <meta name="robots" content="noindex" />
      ) : null}
      {linksData.map((entry) => (
        <link
          rel="alternate"
          hrefLang={entry.hreflang}
          href={entry.href}
          key={entry.href}
        />
      ))}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link rel="canonical" href={getCanonicalLink(pathName)} />
      <body
        className={
          isCustomBackground(pathNameWithoutLocale) ? 'custom-background' : null
        }
      />
    </Helmet>
  );
}

SEO.propTypes = {
  pathName: PropTypes.string.isRequired,
};

export default SEO;
