import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BTN = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(-45deg);
  transition: 300ms all;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    transform: rotate(45deg);
    opacity: 1;
  }

  span {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);

    &:first-child {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:last-child {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
`;

function ToggleBtn({ onClick, isOpen }) {
  return (
    <BTN onClick={onClick} isOpen={isOpen}>
      <span />
      <span />
    </BTN>
  );
}

ToggleBtn.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

ToggleBtn.defaultProps = {
  onClick: null,
  isOpen: false,
};

export default ToggleBtn;
