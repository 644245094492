module.exports = {
  'loading.order': `
    Обработка заказа
  `,
  'loading.payment': `
    Обработка платёжной информации
  `,
  'loading.update_order': `
    Обновление заказа
  `,
};
