import { store } from '../../store';
import ActionType from '../../store/action-type';
import { isItemAccessory } from './items';
import {
  createAccessoryCartItem,
  createDevicePackageCartItem,
} from '../../domain/CartItems/cart-item';

import ProductType from '../../domain/product-type';

export const prepareItem = (item) =>
  isItemAccessory(item)
    ? createAccessoryCartItem(item)
    : createDevicePackageCartItem(item);

export const calculateItemsCount = (items) =>
  items.reduce(
    (count, item) =>
      item.productType === ProductType.coolPrivilege
        ? count
        : count + item.count,
    0
  );

export const updatePriceItems = (payload) => {
  store.dispatch({ type: ActionType.Basket.UpdatePriceItems, payload });
};

export const isItemLeftCheck = (item) => item.count > item.availableQuantity;
