import ActionType from '../action-type';

// eslint-disable-next-line default-param-last
export default function items(state = { list: [], hasItems: false }, action) {
  switch (action.type) {
    case ActionType.Items.Add: {
      return { ...state, list: action.payload, hasItems: true };
    }
    case ActionType.Items.Clear: {
      return { ...state, list: [], hasItems: false };
    }
    default:
      return state;
  }
}
