module.exports = {
  'loading.order': `
    Préparez la commande
  `,
  'loading.payment': `
    Préparez le paiement
  `,
  'loading.update_order': `
    Mettre à jour la commande
  `,
};
