import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import CookieConsent from 'react-cookie-consent';
import styles from './styled';
import { onCookieConsentAccepted } from '../../utils/datalayer/dataLayer';

function Cookies() {
  const intl = useIntl();

  return (
    <CookieConsent
      overlay
      overlayStyle={styles.overlay}
      style={styles.background}
      buttonStyle={styles.button}
      buttonText={intl.formatMessage({ id: 'cookies.accept' })}
      onAccept={onCookieConsentAccepted}
    >
      <FormattedMessage id="cookies.header" />
      <br />
      <FormattedMessage id="cookies.text" />
    </CookieConsent>
  );
}

export default Cookies;
