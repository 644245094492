import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import ActionType from '../store/action-type';
import useDiscountParams from './use-discount-params';

// TODO Handle initialization fail
const useInitialData = () => {
  const { locale: intlLocale } = useIntl();
  const location = useLocation();
  const ipRegistry = useSelector((state) => state.ipRegistry);
  const initialization = useSelector((state) => state.initialization);
  const pickedLocale = useSelector((state) => state.locale.picked);
  const dispatch = useDispatch();
  useDiscountParams();
  useEffect(() => {
    dispatch({
      type: ActionType.Initialization.Start,
      payload: {
        ipRegistry,
        countryCodeInUrl: new URLSearchParams(location.search).get('country'),
      },
    });
  }, []);

  useEffect(() => {
    if (!initialization.done) return;

    const to = location.href.slice(location.origin.length);
    if (to.startsWith(`/${pickedLocale}/`)) return;

    navigate(
      to.startsWith(`/${intlLocale}`)
        ? to.replace(`/${intlLocale}`, `/${pickedLocale}`)
        : `/${pickedLocale}${to}`,
      { replace: true }
    );
  }, [initialization, intlLocale, pickedLocale]);
};

export default useInitialData;
