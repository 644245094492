import ActionType from '../action-type';

// eslint-disable-next-line default-param-last
export default function payment(state = { data: '', id: null }, action) {
  switch (action.type) {
    case ActionType.Payment.SetData:
      return { ...state, data: action.payload };
    case ActionType.Payment.SetId:
      return { ...state, id: action.payload };
    default:
      return state;
  }
}
