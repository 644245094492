import ActionType from '../action-type';

// eslint-disable-next-line default-param-last
export default function countries(state = { list: [], picked: '' }, action) {
  switch (action.type) {
    case ActionType.Countries.Add:
      return { ...state, list: action.payload };
    case ActionType.Countries.Pick:
      return { ...state, picked: action.payload };
    default:
      return state;
  }
}
