import LoadableEntityState from './LoadableEntityState';

const getLoadableEntityReducer =
  (ActionType) =>
  (
    // eslint-disable-next-line default-param-last
    state = { state: LoadableEntityState.Initial, value: null },
    { type, payload }
  ) => {
    if (type === ActionType.Fetch)
      return { ...state, state: LoadableEntityState.Loading };
    if (type === ActionType.Success)
      return { state: LoadableEntityState.Loaded, value: payload };
    if (type === ActionType.Fail)
      return { ...state, state: LoadableEntityState.Fail };
    if (type === ActionType.MarkAsStale)
      return { ...state, state: LoadableEntityState.Stale };

    return state;
  };

export default getLoadableEntityReducer;
