import locales from '../../i18n/locales.json';

const AVAILABLE_LOCALE_LIST = Object.keys(locales);
const LOCALE_BY_COUNTRY_CODE = new Map([
  ['AU', 'en-AU'],
  ['UK', 'en-UK'],
  ['DE', 'de'],
  ['FR', 'fr'],
  ['ES', 'es'],
  ['RU', 'ru'],
]);

export const getLocale = ({ countryCode, locale }) => {
  if (countryCode) {
    const countryCodeInUpperCase = countryCode.toUpperCase();
    if (LOCALE_BY_COUNTRY_CODE.has(countryCodeInUpperCase))
      return LOCALE_BY_COUNTRY_CODE.get(countryCodeInUpperCase);
  }

  if (locale) {
    const localeInLowerCase = locale.toLowerCase();
    if (AVAILABLE_LOCALE_LIST.includes(localeInLowerCase))
      return localeInLowerCase;
  }

  return null;
};

const fetchIpRegistry = async () => {
  const response = await fetch(
    `https://api.ipregistry.co/?key=${process.env.GATSBY_IPREGISTRY_KEY}`
  );
  const responseBody = await response.json();

  const countryCode = responseBody.location?.country?.code;
  const locale =
    responseBody.location?.country?.languages?.[0]?.code ||
    responseBody.location?.language?.code;

  return {
    locale: getLocale({ countryCode, locale }),
    countryCode: countryCode || null,
  };
};

export default fetchIpRegistry;
