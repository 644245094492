import { useState } from 'react';

const useSwitch = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    toggle: () => {
      setValue((prevValue) => !prevValue);
    },
    turnOn: () => {
      setValue(true);
    },
    turnOff: () => {
      setValue(false);
    },
  };
};

export default useSwitch;
