module.exports = {
  'privacy.title': `Privacy Policy`,
  'privacy.text': `By visiting or using our Services, you consent to the practices described in this Privacy Policy. If you have questions about this Privacy Policy, please contact us at `,
  'privacy.item1': `Evapolar collects Personal Data (information that specifically identifies an individual, such as their name, address, telephone number, mobile number or e-mail address or other information about that individual that is directly linked to Personal Data) for the purpose of processing and delivery of the User’s Order, for billing and for establishment of the provisions of these Terms (including but not limited to provisions related to warranties, returns and exchanges). By placing an Order the User has agreed to provide Evapolar with actual and accurate information required for the purposes mentioned above.`,
  'privacy.item2': `Evapolar does not share Personal Data of the User with third parties, except for those who are engaged in the processing and delivery of the User’s Order, billing and establishment of provisions of these Terms.`,
  'privacy.item3': `Evapolar reserves the right to disclose Personal Data (i) as required by law and when Evapolar reasonably assumes this disclosure is necessary to protect Evapolar’s rights; (ii) to comply with judicial proceedings, court orders or legal process served on Evapolar or the Website; (iii) in connection with an actual or proposed corporate transaction or insolvency proceeding involving all or a part of the business or asset to which the Personal Data pertains.`,
  'privacy.item4': `Evapolar may also track certain non-personal data related to the User’s use of the Website by way of using different technologies (such as Cookies, log files or web bugs) for statistical, internal analytics and research purposes. Evapolar may share such non-personal data in accordance with its business needs.`,
  'privacy.item5': `Evapolar’s Privacy Policy covers only information that is collected on this website and on <a href='https://hi.evapolar.com'>hi.evapolar.com</a> and does not cover any other website to which it may link.`,
  'privacy.item6': `Evapolar may use an e-mail provided by the User for notification, security, support and maintenance advisories, newsletters, surveys, offers, and other promotional materials related to the Product or changes to these Terms. Evapolar provides the User with the right to “opt-out” of using Personal Data by Evapolar for promotional communication by sending an email to support@evapolar.com.`,
  'privacy.item7': `Evapolar may receive comments and feedback related to the Product or the Website from Users and place it on the Website or other sources for promotional purposes and other Evapolar’s business needs. For publishing such content Evapolar may identify the authors by their first and last name and may also indicate their home city. By providing such comments and feedback the User gives Evapolar consent to post his or her name along with the comment or feedback.`,
  'privacy.item8': `Rakuten Advertising may collect personal information when you interact with our digital property, including IP addresses, digital identifiers, information about your web browsing and app usage and how you interact with our properties and ads for a variety of purposes, such as personalization of offers or advertisements, analytics about how you engage with websites or ads and other commercial purposes. For more information about the collection, use and sharing of your personal data and your rights, please use the below links.\nRakuten Advertising\nPrivacy Policy : <privacyPolicy>https://rakutenadvertising.com/legal-notices/services-privacy-policy/</privacyPolicy>\nYour Rights : <subjectRequests>https://rakutenadvertising.com/legal-notices/subject-requests/</subjectRequests>`,
};
