/**
 * @param {string} eventName
 * @param {number | string} id
 * @param {number | string} price
 * @return {Object}
 */
export default function getDataLayerEventForSingleItem(eventName, id, price) {
  return {
    event: 'gtagDynRem',
    dynRemEventName: eventName,
    dynRemValue: Number(price),
    dynRemFBItems: {
      id: Number(id),
    },
    dynRemItems: {
      id: Number(id),
      google_business_vertical: 'retail',
    },
  };
}
