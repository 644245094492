module.exports = {
  'loading.order': `
    Bestellung vorbereiten
  `,
  'loading.payment': `
    Zahlung vorbereiten
  `,
  'loading.update_order': `
    Die Aktualisierung der Bestellung
  `,
};
