import { useIntl } from 'react-intl';
import { navigate } from 'gatsby';
import localizeLink from '../utils/localize-link';

function useI18nNavigation() {
  const intl = useIntl();

  return (to) => {
    navigate(localizeLink(intl.locale, to));
  };
}

export default useI18nNavigation;
