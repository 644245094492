import PropTypes from 'prop-types';
import React from 'react';
import useInitialData from '../../hooks/use-initial-data';
import GoToCheckoutPopup from '../Checkout/PopupFromUrl';
import Cookies from '../Cookies';

function LayoutWrap({ children }) {
  useInitialData();

  return (
    <>
      {children}
      <GoToCheckoutPopup />
      <Cookies />
    </>
  );
}

LayoutWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWrap;
