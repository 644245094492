import { colors } from '../../ui/common';

const zIndex = Number.MAX_SAFE_INTEGER;

export default {
  overlay: {
    zIndex,
  },
  background: {
    background: '#fff',
    color: colors.color06,
    alignItems: 'center',
    zIndex,
  },
  button: {
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    height: '52px',
    lineHeight: '48px',
    borderRadius: '4px',
    minWidth: '158px',
    fontWeight: '500',
    fontSize: '14px',
    padding: '0 24px',
    textAlign: 'center',
    border: '1px solid transparent',
    textDecoration: 'none',
    cursor: 'pointer',
    backgroundColor: colors.color06,
    color: 'white',
    outline: 'none',
    transition: '0.25s ease-out',
  },
};
