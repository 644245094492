import { createTheme } from '@material-ui/core/styles';
import { colors } from './common';

export default createTheme({
  // text
  typography: {
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    textTransform: 'none',
    h1: {
      fontWeight: '500',
      fontSize: '48px',
      lineHeight: '122.9%',
    },
    h2: {
      fontWeight: '600',
      fontSize: '38px',
      lineHeight: '150%',
    },
    h3: {
      fontWeight: '600',
      fontSize: '32px',
      lineHeight: '150%',
    },
    p1: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '180%',
    },
    p2: {
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '170%',
    },
    p3: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '189.9%',
    },
    title: {
      fontWeight: '300',
      fontSize: '32px',
      lineHeight: '170%',
    },
    body1: {
      color: colors.color01,
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: colors.color05,
      },
    },
  },
});
