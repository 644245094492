import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

// TODO Move styles to separate file
const Wrapper = styled.div`
  overflow: hidden;
`;
const ImageWrapper = styled.div`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;

  & .image1 {
    height: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }
  & .image2 {
    height: 100%;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 540px) {
      display: none;
    }
  }
  & .image3 {
    height: 100%;
    display: none;

    @media (max-width: 540px) {
      display: block;
    }
  }
`;

const IMAGE_PROPS = {
  loading: 'eager',
  quality: 90,
  alt: 'eva conditioner',
  layout: 'constrained',
};

function BackgroundImage() {
  return (
    <Wrapper>
      <ImageWrapper>
        <StaticImage
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...IMAGE_PROPS}
          className="image1"
          src="../images/home-page/header/hero_1440px.jpg"
          alt="Evapolar air conditioner"
        />
        <StaticImage
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...IMAGE_PROPS}
          className="image2"
          src="../images/home-page/header/hero_768px.jpg"
          alt="Evapolar air conditioner"
        />
        <StaticImage
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...IMAGE_PROPS}
          className="image3"
          src="../images/home-page/header/hero_540px.jpg"
          alt="Evapolar air conditioner"
        />
      </ImageWrapper>
    </Wrapper>
  );
}

export default BackgroundImage;
