module.exports = {
  'product.guides.header.variant1': `Evapolar\nProduct guides`,
  'product.guides.header.variant2': `Evapolar product\nguides`,

  'product.guides.evachill.title': `evaCHILL`,
  'product.guides.evachill.img.alt': `evaCHILL image`,

  'product.guides.evalightplus.title': `evaLIGHTplus`,
  'product.guides.evalightplus.img.alt': `evaLIGHTplus image`,

  'product.guides.evasmart.title': `evaSMART`,
  'product.guides.evasmart.img.alt': `evaSMART image`,

  'product.guides.language.deutsch': `Deutsch`,
  'product.guides.language.français': `Français`,
  'product.guides.language.suomi': `Suomi`,
  'product.guides.language.dansk': `Dansk`,
  'product.guides.language.svenska': `Svenska`,
};
