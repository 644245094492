import * as BasketSelectors from '../selectors/basket';
import * as CountriesSelectors from '../selectors/countries';
import ActionType from '../action-type';
import { getApiURL } from '../../utils/api';
import * as dataLayer from '../../utils/datalayer/dataLayer';
import { errorCodes } from '../../utils/api/discount';

export default function createHandleBasketUpdateThunk(list) {
  return async function handleBasketUpdateThunk(dispatch, getState) {
    const items = list || BasketSelectors.selectItems(getState());
    const promoCode = BasketSelectors.selectPromoCode(getState());
    const pickedCountryCode = CountriesSelectors.selectPickedCountryCode(
      getState()
    );

    const sendData = {
      items: items.map((v) => ({ variant: v.id, quantity: v.count })),
      promoCode,
      country: pickedCountryCode,
    };

    try {
      const response = await fetch(`${getApiURL()}v0/orders/processCart/`, {
        method: 'POST',
        body: JSON.stringify(sendData),
        headers: { 'content-type': 'application/json' },
      });
      const data = await response.json();
      if (sendData.promoCode && data.errors.length === 0)
        dataLayer.reportDiscount(sendData.promoCode);
      dispatch({
        type: ActionType.Basket.UpdateType,
        // This one
        payload: {
          taxAmount: Number(data.taxAmount),
          totalPriceWithDiscTaxed: Number(data.totalPriceWithDiscTaxed),
          vatValue: Math.ceil(Number(data.vatValue)),
          cartSubtotal: Number(data.totalPriceWithDisc),
          trueSubtotal: Number(data.totalPrice),
          discountError: data.errors[0] || errorCodes.DISCOUNT_OK,
          list: items.map((requestedItem) => ({
            ...requestedItem,
            ...data.order_variants.find(
              ({ variant }) => variant === requestedItem.id
            ),
          })),
          ...(data.errors.length === 0
            ? {
                cartDiscount: Number(data.totalDiscountAmount),
                promoCodeFinal: sendData.promoCode,
              }
            : { promoCodeFinal: '' }),
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
}
