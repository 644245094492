import ActionType from '../action-type';

// eslint-disable-next-line default-param-last
export default function locale(state = { picked: '' }, action) {
  switch (action.type) {
    case ActionType.Locale.Set:
      return { ...state, picked: action.payload };
    default:
      return state;
  }
}
