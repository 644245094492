import getDataLayerEventForSingleItem from './get-data-layer-event-for-single-item';
import getDataLayerEventForItemsList from './get-data-layer-event-for-items-list';
import itemsToProducts from './items-to-products';
import {
  getItemCategory,
  getItemColorAttribute,
  getItemName,
  getItemPlug,
  getItemPrice,
  getItemPriceCurrency,
  getItemProductId,
  getItemSKU,
  getItemSlug,
} from '../api/items';
import { isProduction } from '../settings';
import { getItemCount } from '../api/basket_extended';
import { getStore } from '../../store';
import isWindowValid from '../is-window';

// eslint-disable-next-line prefer-destructuring
const GATSBY_AFFILIATLY_ID = process.env.GATSBY_AFFILIATLY_ID;

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

function pushDataLayer(event) {
  if (isProduction) {
    if (!isWindowValid('dataLayer')) return;

    window.dataLayer.push(event);
    return;
  }
  // eslint-disable-next-line no-console
  console.log('Data layer event:', event);
}

function generateFBP() {
  return getCookie('_fbp');
}

function generateFBC() {
  return getCookie('_fbc');
}

function sendFacebookApiRequest(event, data) {
  try {
    const location = window.location || {};
    const { protocol, host, pathname } = location;
    const reqBody = {
      eventName: event,
      fbc: generateFBC(),
      fbp: generateFBP(),
      custom: data,
      sourceUrl: `${protocol}//${host}${pathname}`,
      source: 'website',
    };
    return fetch(`/api/createFbRequest`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
      headers: { 'content-type': 'application/json' },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('FB request error: ', e);
  }
  return null;
}

export function pushFacebookPixel(event, data) {
  if (isProduction) {
    sendFacebookApiRequest(event, data);
    return;
  }
  // eslint-disable-next-line no-console
  console.log('FB Pixel Data layer event:', event, data);
}

function pushRakuten(event) {
  if (isProduction) {
    window.rm_trans = event;
    return;
  }
  // eslint-disable-next-line no-console
  console.log('Data layer rakuten event:', event);
}

function pushMixpanel(event, data) {
  if (isProduction) {
    if (!isWindowValid('mixpanel')) return;

    window.mixpanel.track(event, data);
    return;
  }
  // eslint-disable-next-line no-console
  console.log('Mixpanel event: ', event, data);
}

function pushAffiliatly(data) {
  // eslint-disable-next-line camelcase
  const { order, price, coupon_code } = data;

  if (isProduction) {
    if (!isWindowValid('markPurchase')) return;

    window.markPurchase(GATSBY_AFFILIATLY_ID, order, price, coupon_code);
    return;
  }

  // eslint-disable-next-line no-console
  console.log('Affiliatly event: ', data);
}

export const onViewAllProductsDataLayer = (items, page) => {
  const productsFromContextState = itemsToProducts(items);
  const productNames = Object.keys(productsFromContextState);
  const dataLayerArray = [];
  productNames.forEach((productName) => {
    const { id, finalCost, alias, currency } =
      productsFromContextState[productName];

    if (finalCost) {
      dataLayerArray.push({
        id,
        name: alias,
        price: finalCost,
        currency,
      });
    }
  });

  if (dataLayerArray.length === productNames.length) {
    pushDataLayer({
      event: 'addEcommerce',
      event_id: 'd-n01-e1',
      ecommerce_step: 'productImpression',
      ecommerce: {
        currencyCode: dataLayerArray[0].currency,
        impressions: dataLayerArray.map((product, index) => ({
          name: product.name,
          price: product.price,
          category: 'Personal Air Conditioner',
          id: product.id,
          list: page,
          position: index,
        })),
      },
    });
    pushDataLayer(
      getDataLayerEventForItemsList('view_item_list', dataLayerArray)
    );
  }
};

export const onClickProductDataLayer = (
  item,
  position = 1,
  page = 'main page'
) => {
  const id = String(getItemProductId(item));
  const name = getItemSlug(item);
  const price = +getItemPrice(item);
  pushDataLayer({
    event: 'addEcommerce',
    event_id: 'd-n01-e2',
    ecommerce_step: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: page },
        products: [
          {
            name,
            price,
            position,
            id,
            category: 'Personal Air Conditioner',
          },
        ],
      },
    },
  });
};

export const onViewProductDataLayer = (item) => {
  const id = String(getItemProductId(item));
  const name = getItemSlug(item);
  const price = +getItemPrice(item);
  const currency = getItemPriceCurrency(item);
  pushMixpanel('shop', {
    'product name': name,
    'product id': id,
    'product price': price,
    'product category': 'Personal Air Conditioner',
  });
  pushDataLayer({
    event: 'addEcommerce',
    event_id: 'd-n01-e3',
    ecommerce_step: 'productDetail',
    ecommerce_param: 'none',
    ecommerce: {
      detail: {
        products: [
          {
            name,
            price,
            id,
            category: 'Personal Air Conditioner',
          },
        ],
      },
    },
  });
  pushDataLayer(getDataLayerEventForSingleItem('view_item', id, price));
  pushFacebookPixel('ViewContent', {
    content_type: 'product',
    content_ids: id,
    content_name: name,
    value: price,
    currency,
  });
};

export const addToCart = (item) => {
  const name = getItemName(item);
  const color = getItemColorAttribute(item);
  const plug = getItemPlug(item);
  const id = String(getItemProductId(item));
  const price = Number(getItemPrice(item));
  const currency = getStore().getState().basket.defaultCurrency; // TODO Use selector instead!
  pushDataLayer({
    event: 'addEcommerce',
    event_id: 'd-n01-e4',
    ecommerce_step: 'addToCart',
    ecommerce: {
      add: {
        products: [
          {
            name,
            id,
            price,
            category: getItemCategory(item),
            variant: `${color} / ${plug}`,
            dimension1: color,
            dimension2: plug,
            quantity: 1,
          },
        ],
      },
    },
  });
  pushDataLayer(getDataLayerEventForSingleItem('add_to_cart', id, price));
  pushFacebookPixel('AddToCart', {
    currency,
    content_type: 'product',
    content_ids: id,
    value: price,
    content_name: name,
  });
};

export const removeFromCart = (item, quantity) => {
  const color = getItemColorAttribute(item);
  const plug = getItemPlug(item);
  const id = String(getItemProductId(item));
  const price = Number(getItemPrice(item));
  pushDataLayer({
    event: 'addEcommerce',
    event_id: 'd-n01-e5',
    ecommerce_step: 'removeFromCart',
    ecommerce: {
      add: {
        products: [
          {
            name: getItemName(item),
            id,
            price,
            category: getItemCategory(item),
            variant: `${color} / ${plug}`,
            dimension1: color,
            dimension2: plug,
            quantity,
          },
        ],
      },
    },
  });
};

function cartItemsToAnalytic(cartItems) {
  const products = cartItems.map((item) => {
    const color = getItemColorAttribute(item);
    const plug = getItemPlug(item);
    const id = String(getItemProductId(item));
    const price = Number(getItemPrice(item));
    const { quantity } = item;
    return {
      name: getItemName(item),
      id,
      price,
      category: getItemCategory(item),
      variant: `${color} / ${plug}`,
      dimension1: color,
      dimension2: plug,
      quantity,
    };
  });
  const mixpanelProducts = cartItems.map((item) => {
    const name = getItemName(item);
    const id = String(getItemProductId(item));
    const price = Number(getItemPrice(item));
    return {
      name,
      id,
      price,
      category: getItemCategory(item),
    };
  });
  const rakutenProducts = cartItems.map((item) => {
    const name = getItemName(item);
    const quantity = getItemCount(item);
    const priceProductWithoutVAT = getItemPrice(item);
    const sku = getItemSKU(item);
    return {
      quantity,
      unitPrice: priceProductWithoutVAT,
      unitPriceLessTax: priceProductWithoutVAT,
      SKU: sku,
      productName: name,
    };
  });
  const fbPixelProducts = cartItems.map((item) => {
    const quantity = getItemCount(item);
    const id = String(getItemProductId(item));
    return {
      id,
      quantity,
    };
  });
  return {
    products,
    mixpanelProducts,
    rakutenProducts,
    fbPixelProducts,
  };
}

export const reportCheckoutStep = (items, step) => {
  const { products, mixpanelProducts } = cartItemsToAnalytic(items);
  const stepList =
    step === 1 ? Array.from({ length: 3 }, (empty, i) => i + 1) : [step];
  stepList.forEach((checkoutPostfix) => {
    pushDataLayer({
      event: 'addEcommerce',
      event_id: 'd-n01-e6',
      ecommerce_step: `checkout${checkoutPostfix}`,
      ecommerce: {
        checkout: {
          actionField: { step },
          products,
        },
      },
    });
  });
  pushMixpanel('cart', mixpanelProducts);
};

export const initiateCheckout = () => {
  pushFacebookPixel('InitiateCheckout', {});
};

/**
 *
 * @param items {Array} Cart items
 * @param orderData {Object} orderData
 * @param orderData.orderId  {string} Order session id
 * @param orderData.subtotal {Number} Items price with discount
 * @param orderData.shipping {Number} Shipping price
 * @param orderData.taxes    {Number} Taxes amount
 * @param orderData.currency {String} Currency
 * @param orderData.discountName {String} Promo code
 * @param orderData.discountAmount {Number} Discount amount
 */
export const reportOrderComplete = (items, orderData) => {
  const { products, mixpanelProducts, rakutenProducts, fbPixelProducts } =
    cartItemsToAnalytic(items);
  console.log(products, 666555666);
  pushMixpanel('purchase', mixpanelProducts);
  pushDataLayer({
    event: 'addEcommerce',
    event_id: 'd-n01-e7',
    ecommerce_step: 'transaction',
    ecommerce: {
      purchase: {
        actionField: {
          id: orderData.orderId,
          revenue: orderData.subtotal,
          shipping: orderData.shipping,
          tax: orderData.taxes,
        },
        products,
      },
    },
  });
  pushDataLayer(
    getDataLayerEventForItemsList(
      'purchase',
      products.map((v) => ({
        id: v.id,
        price: v.price,
      }))
    )
  );
  pushRakuten({
    affiliateConfig: {
      ranMID: '44722',
      discountType: 'item',
      includeStatus: 'false',
    },
    orderid: orderData.orderId,
    currency: orderData.currency,
    customerStatus: '',
    conversionType: 'Sale',
    customerID: '',
    discountCode: orderData.discountName,
    discountAmount: orderData.discountAmount,
    taxAmount: orderData.taxes,
    lineitems: rakutenProducts,
  });
  pushFacebookPixel('Purchase', {
    content_type: 'product',
    contents: fbPixelProducts,
    currency: orderData.currency,
    value: orderData.subtotal,
  });
  pushAffiliatly({
    order: orderData.orderId,
    price: orderData.subtotal,
    coupon_code: orderData.discountName || '',
  });
};

/**
 * Reports promo code usage
 * @param promoCode {string} Promo code string
 */
export const reportDiscount = (promoCode) => {
  pushDataLayer({
    event: 'addEvents',
    event_id: 'd-n01-e8',
    eventCategory: 'Conversions',
    eventAction: 'Promocode',
    eventLabel: promoCode,
  });
};

export const mixpanelTrack = (eventName) => {
  pushMixpanel(eventName);
};

export const onCookieConsentAccepted = () => {
  /* eslint-disable-next-line no-underscore-dangle */
  window.__rmcp2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
};
