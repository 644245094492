import { call, race, all, delay, put, fork, take } from 'redux-saga/effects';
import defaultCountryCode from '../../i18n/default-country';
import defaultLocale from '../../i18n/default-locale';
import ActionType from '../action-type';
import fetchIpRegistry, { getLocale } from '../../utils/api/fetchIpRegistry';
import fetchCountryList from '../../utils/api/fetchCountryList';
import fetchItems from '../../utils/api/fetchItems';
import LoadableEntityState from '../reducers/utils/LoadableEntityState';

const IP_REGISTRY_FALLBACK = { countryCode: null, locale: null };

function takeOnlyOnce(pattern, saga, ...args) {
  return fork(function* takeOnlyOnceGen() {
    const action = yield take(pattern);
    yield call(saga, ...args.concat(action));
  });
}

// TODO DRY(packages/frontend/src/utils/api/items.js)
// It is not imported because of dependency cycle.
// This dependency cycle can be solved by removing the store dependency in packages/frontend/src/utils/api/items.js
// TODO Use camelCase: `country_prices` -> `countryPrices`
const getItemPriceCurrency = (item) =>
  // eslint-disable-next-line camelcase
  item?.country_prices?.[0]?.price?.currency || 'USD';

const getKnownCountryCode = ({ countryCode, countryList }) => {
  const country = countryList.find(
    ({ code2 }) => code2?.toLowerCase() === countryCode.toLowerCase()
  );

  return country?.code2 || null;
};

const getCountryCode = ({ countryCodeInUrl, ipRegistry, countryList }) => {
  if (countryCodeInUrl) {
    const knownCountryCode = getKnownCountryCode({
      countryCode: countryCodeInUrl,
      countryList,
    });
    if (knownCountryCode) return knownCountryCode;
  }

  if (ipRegistry.countryCode) {
    const knownCountryCode = getKnownCountryCode({
      countryCode: ipRegistry.countryCode,
      countryList,
    });
    if (knownCountryCode) return knownCountryCode;
  }

  return defaultCountryCode;
};

function* fetchIpRegistryWorker() {
  try {
    const [ipRegistry, timeout] = yield race([
      call(fetchIpRegistry),
      delay(5000),
    ]);
    if (timeout) {
      console.error(`Timeout on ip registry fetch`);
      yield put({ type: ActionType.IpRegistry.Fail });
      return IP_REGISTRY_FALLBACK;
    }
    yield put({ type: ActionType.IpRegistry.Success, payload: ipRegistry });
    return ipRegistry;
  } catch (error) {
    console.error(`Can't fetch ip registry`, error);
    yield put({ type: ActionType.IpRegistry.Fail });
    return IP_REGISTRY_FALLBACK;
  }
}

function* initialize(action) {
  try {
    const { ipRegistry: passedIpRegistry, countryCodeInUrl } = action.payload;
    let ipRegistry;
    let countryList;
    if (
      passedIpRegistry.state === LoadableEntityState.Initial ||
      passedIpRegistry.state === LoadableEntityState.Stale
    ) {
      [ipRegistry, countryList] = yield all([
        call(fetchIpRegistryWorker),
        call(fetchCountryList),
      ]);
    } else {
      countryList = yield call(fetchCountryList);
      ipRegistry = passedIpRegistry.value;
    }
    const countryCode = getCountryCode({
      countryCodeInUrl,
      ipRegistry,
      countryList,
    });
    const locale = getLocale({ countryCode, locale: defaultLocale });
    yield put({ type: ActionType.Locale.Set, payload: locale });
    yield put({ type: ActionType.Countries.Add, payload: countryList });
    yield put({ type: ActionType.Countries.Pick, payload: countryCode });

    const items = yield call(fetchItems, countryCode);
    yield put({ type: ActionType.Items.Add, payload: items });
    yield put({
      type: ActionType.Basket.UpdateType,
      payload: {
        defaultCurrency:
          items.length > 0 ? getItemPriceCurrency(items[0]) : 'USD',
      },
    });

    yield put({ type: ActionType.Initialization.Success });
  } catch (error) {
    console.error(`Can't initialize`, error);
    yield put({ type: ActionType.Initialization.Fail });
  }
}

export default function* subscriber() {
  yield takeOnlyOnce(ActionType.Initialization.Start, initialize);
}
