import ActionType from '../action-type';

// eslint-disable-next-line default-param-last
export default function initialization(state = { done: false }, action) {
  if (action.type === ActionType.Initialization.Success) {
    return { ...state, done: true };
  }
  // TODO Handle ActionType.Initialization.Fail

  return state;
}
