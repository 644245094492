function isCartridge(slug) {
  return (
    slug === 'evalight-ev-1000-cartridge' ||
    slug === 'evasmart-ev-3000-cartridge' ||
    slug === 'evachill-ev-500-cartridge' ||
    slug === 'evalight-plus-ev-1500-cartridge'
  );
}

function isDevice(slug) {
  return (
    slug === 'evalight-ev-1000' ||
    slug === 'evasmart-ev-3000' ||
    slug === 'evachill-ev-500' ||
    slug === 'evalight-plus-ev-1500'
  );
}

function isEvaPureSlug(slug) {
  return slug === 'evapure';
}

function isSpecifiedDevice(item, deviceName) {
  return (
    item.product &&
    isDevice(item.product.slug) &&
    item.product.name.startsWith(deviceName)
  );
}

function isEvaChill(item) {
  return isSpecifiedDevice(item, 'evaCHILL');
}

function isEvaPure(item) {
  return item.product && isEvaPureSlug(item.product.slug);
}

function isEvaLightPlus(item) {
  return isSpecifiedDevice(item, 'evaLIGHT plus');
}

function isEvaSmart(item) {
  return isSpecifiedDevice(item, 'evaSMART');
}

function isPackage(slug) {
  return (
    slug === 'evachill-ev-500-package' ||
    slug === 'evalight-air-conditioner-cartridge' ||
    slug === 'evasmart-air-conditioner-cartridge' ||
    slug === 'evalight-plus-ev-1500-package'
  );
}

function isCartridgeVariant(item) {
  return item.product && isCartridge(item.product.slug);
}

function isPlainAccessory(slug) {
  return (
    slug === 'evabottle' ||
    slug === 'evaaroma-set' ||
    slug === 'evabag' ||
    slug === 'evabank'
  );
}

function isShopAccessory(slug) {
  return isCartridge(slug) || isPlainAccessory(slug);
}

function isAccessoryVariant(item) {
  return isShopAccessory(item.product.slug);
}

export {
  isCartridge,
  isCartridgeVariant,
  isAccessoryVariant,
  isSpecifiedDevice,
  isEvaChill,
  isEvaPure,
  isEvaLightPlus,
  isEvaSmart,
  isDevice,
  isPackage,
  isPlainAccessory,
  isShopAccessory,
};
