module.exports = {
  'loading.order': `
    Preparing order
  `,
  'loading.payment': `
    Preparing payment
  `,
  'loading.update_order': `
    Updating order
  `,
};
