import { combineReducers } from 'redux';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import ActionType from '../action-type';
import countries from './countries';
import basket from './basket';
import items from './items';
import checkout from './checkout';
import payment from './payment';
import locale from './locale';
import preferences from './preferences';
import initialization from './initialization';
import warrantyRegistrationForm from './warrantyRegistrationForm';
import getLoadableEntityReducer from './utils/getLoadableEntityReducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['initialization'],
};

const warrantyRegistrationFormPersistConfig = {
  key: 'warrantyRegistrationForm',
  storage,
  blacklist: ['isHasBeenSubmittedAtLeastOnce'],
  stateReconciler: hardSet,
};

const rootReducer = combineReducers({
  countries,
  basket,
  items,
  checkout,
  payment,
  locale,
  preferences,
  initialization,
  warrantyRegistrationForm: persistReducer(
    warrantyRegistrationFormPersistConfig,
    warrantyRegistrationForm
  ),
  ipRegistry: getLoadableEntityReducer(ActionType.IpRegistry),
});

export default persistReducer(rootPersistConfig, rootReducer);
