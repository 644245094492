export const getCartItemAlternateId = (dualItem) => {
  if (!dualItem.dual) {
    return null;
  }
  return dualItem.items[1 - dualItem.currentIdx].id;
};

export const toggleDualItem = (dualItem) => {
  if (!dualItem.dual) {
    return dualItem;
  }
  const currentIdx = 1 - dualItem.currentIdx;
  return {
    ...dualItem,
    currentIdx,
    ...dualItem.items[currentIdx],
    // TODO Hacky fucking piece of shit to avoid dependency cycle!
    isAlternated: currentIdx !== 0,
  };
};
