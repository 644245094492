export default function localizeLink(locale, to) {
  let postfix = to
    .split('/')
    .filter((v) => v !== '')
    .join('/');
  while (postfix[postfix.length - 1] === '/') postfix = postfix.slice(0, -1);
  if (postfix) postfix = `${postfix}/`;

  return `/${locale}/${postfix}`;
}
