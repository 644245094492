import styled, { css } from 'styled-components';

export const colors = {
  // These colors are the same for old and new kits
  accent: '#FDC1AA',
  error: '#D23F36',

  // TODO Is `color01` and `color05` the same?
  color01: '#000',
  color02: '#000',
  color03: '#fcecec',
  color04: '#f6dede',
  color05: '#000', // button borders, some text, lines
  color06: '#000',
  color07: '#e6f1f6',
  color08: '#84b8d0', // Medium Blue
  color09: '#373737',
  color10: '#e6f1f6',
  color11: '#37373766', // color picker border
  color12: '#d8d8d8', // plug/color hover/active border
  color13: '#6995a8', // Selected plug/color background
  color14: '#000', // logo text color
  color15: '#fff', // logo text color on top
  color16: '#fdc1aa', // logo dot color
  color17: '#121929', // new color text
  color18: '#5F6EFF',
  color19: '#1C2431',
  color20: '#919FB4',
  color21: '#BDC5D2',
  color22: '#66707E',
  color23: '#919BA9',
  color24: '#637185',
  color25: '#141A23',
  color26: '#323232',
  color27: '#5D93FF',
  color28: '#3E3E3E',
};
export const colorsBar = {
  white: '#FFFFFF',
  black: '#515151',
  grey: '#808080',
  pink: '#FFC0CB',
  blue: '#0594B3',
  blueDark: '#06678F',
  lavender: '#E6E6FA',
};
export const sizes = {
  fullHD: 1920,
  desktopLarge: 1440,
  desktop: 1400,
  laptop: 1200,
  medium: 960,
  tablet: 768,
  phone: 600,
  iphone5: 321, // use only for mediaMax.
};

export const sizesInvert = {
  fullHD: 1919,
  desktopLarge: 1439,
  desktop: 1399,
  laptop: 1199,
  medium: 959,
  tablet: 767,
  phone690: 690,
  phone: 599,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => `
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const mediaMax = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => `
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const mediaInv = Object.keys(sizesInvert).reduce(
  (accumulator, label) => {
    // const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => `
    @media (max-width: ${sizesInvert[label]}px) {
      ${css(...args)};
    }
  `;
    return accumulator;
  },
  {}
);

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  ${media.medium`
    max-width: 930px;
  `}
  ${media.laptop`
    max-width: 1140px;
  `}
`;

export const ContBig = styled.section`
  max-width: 638px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  ${media.medium`
    max-width: 930px;
  `}
  ${media.laptop`
    max-width: 1310px;
  `}
`;

export const Headline = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  color: ${colors.color05};
  ${media.phone`
    font-size: 38px;
  `}
`;

export const text = css`
  font-weight: 300;
  font-size: 13px;
  color: ${colors.color01};
`;

export const Text = styled.div`
  ${text};
  line-height: 1.75;
`;
