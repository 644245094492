module.exports = {
  'faq.title': `
     ¿Tiene una pregunta?
  `,

  'faq.text': `
    Lea respuestas a las preguntas más 
   frecuentes o haga su propia pregunta.
  `,

  'faq.question1': '¿Cómo funciona Evapolar?',
  'faq.answer1': `
   Evapolar es un dispositivo portátil personal que no sólo enfría, sino también humedece y purifica el aire a su alrededor. El principio operativo es muy simple: sólo rellene el tanque de agua y conecta Evapolar. En 2-3 minutos el cartucho de Evapolar absorberá una gran cantidad de agua. Luego el agua se distribuye de manera uniforme sobre la superficie de los paneles de refrigeración. Cuando el aire circula a través de los paneles, el agua se evapora, lo que hace que el aire se enfríe al saturarse de agua. El ventilador incorporado ayuda a extender el aire por un área de 45 pies cuadrados (3-4 m²) a su alrededor. Control de potencia le permite ajustar el efecto de enfriamiento, creando las condiciones térmicas más confortables para usted.`,

  'faq.question2':
    'Qué diferencia hay entre Evapolar y otros dispositivos de enfriamiento?',
  'faq.answer2': `
    Evapolar supera otros dispositivos de enfriamiento en muchos aspectos.
    Ante todo, Evapolar es un dispositivo personal.
    Puede ayudarle a crear su propio microclima más cómodo
    sin invadir el espacio personal de la gente a su alrededor.
    En segundo lugar, llamamos Evapolar "un aire acondicionado" más que
    "un enfriador de aire" gracias a su eficiencia comparando con los aparatos de aire acondicionado
    "regulares". Evapolar permanece dentro de una gama de precios de los dispositivos de mesa
    asequibles, no requiere ningunos gastos de instalación y 
    está listo para usar una vez sacado de la caja.
  `,
  'faq.answer2.2': `
    En tercer lugar, Evapolar refresca el aire con agua que se evapora,
    lo que además hace este dispositivo un humidificador eficiente.
    Usted no tendrá que comprar un separado aire acondicionado, humidificador
    ni depurador. Nuestra tecnología combina las tres funciones
    en un solo dispositivo. Ésta es la forma en que Evapolar crea el llamado
    "microclima personal." Y por último pero no menos importante,
    Evapolar usa 100 veces menos energía que un sistema dividido
    estándar – sólo 10W – lo que por cierto tendrá un efecto positivo
    en su factura de la electricidad
  `,

  'faq.question3': '¿Evapolar funciona sin fuente de energía?',
  'faq.answer3': `
    Para usar Evapolar hay que conectarlo a una fuente de energía.
    Pero ésta no tiene que ser una toma de corriente.
    Puede conectar Evapolar a un banco de energía, 
    un puerto USB de su coche o portátil, o a una batería solar.
    La única condición es que la fuente de alimentación tenga
    una salida de 5V y 2A.
  `,

  'faq.question4':
    '¿Hasta qué punto Evapolar reduce la temperatura y qué tamaño tiene el área de enfriamiento?',
  'faq.answer4': `
    La caída máxima de temperatura es 59°F (15°C). Cabe mencionar que el cambio de temperatura depende de las condiciones externas y del poder del dispositivo. En las regiones de alta humedad la diferencia puede ser al máximo 41°F (5°C) grados, en las regiones secas y calurosas puede ser hasta 59°F (15°C). Hemos probado el dispositivo por todo el mundo y llegado a la conclusión de que cada uno determina la caída confortable de temperatura según su percepción personal. Su propio cuerpo será siempre el indicador mejor, ya que sus necesidades varían enormemente según su clima nativo y temporada actual.`,
  'faq.answer4.2': `
    Aparatos de aire acondicionado tradicionales no tienen en cuenta este factor. Singapur puede ser un ejemplo vivido: usted puede conseguir el confort necesario con una caída de temperatura de 41°F (5°C) mientras que una caída de 50°F (10°C) ya es desagradable para su piel.`,
  'faq.answer4.3': `
    La zona de enfriamiento de Evapolar es 45 ft² (3-4 m²). Evapolar es un dispositivo personal elaborado especialmente para áreas pequeñas. Le ayudará a usted crear las condiciones más confortables en su lugar de trabajo o en el espacio recreativo.
  `,

  'faq.question5':
    '¿Requiere Evapolar una instalación extra o mantenimiento? ¿Qué hago si funciona mal?',
  'faq.answer5': `
    Evapolar es muy fácil de usar y listo para funcionar
    una vez sacado de la caja. Todo lo que usted necesita hacer es rellenar
    el tanque de agua y enchufar el dispositivo. El tanque de agua tiene que ser
    rellenado cada 6-8 horas a medida que el agua se evapora.
    Usted puede encontrar más detalles sobre el uso de Evapolar en el manual incluido.
    En general, es sólo importante evitar que el dispositivo
    se volque y limpiar la sala de funcionamiento una vez al mes.
  `,
  'faq.answer5.2': `
    En caso de mal funcionamiento tiene que contactar con nuestro servicio
    de asistencia en support@evapolar.com o con su distribuidor local
    (si usted no ha comprado su dispositivo en nuestro sitio web).
  `,
};
