import { getApiURL } from '../api';

const fetchItems = async (countryCode) => {
  const response = await fetch(
    `${getApiURL()}v0/products/variants/?country=${countryCode}`
  );
  return response.json();
};

export default fetchItems;
