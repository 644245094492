/* eslint-disable import/prefer-default-export */
import { store } from '../../store';

export const getItemCount = (payload) => {
  const item = store
    .getState()
    .basket.list.find((value) => value.id === payload.id);

  if (item) return item.count;

  return 0;
};
/* eslint-enable import/prefer-default-export */
