import defaultsDeep from 'lodash/defaultsDeep';

import en from './messages/en/index';
import fr from './messages/fr/index';
import es from './messages/es/index';
import de from './messages/de/index';
import enAU from './messages/en_au/index';
import enUK from './messages/en_uk/index';
import ru from './messages/ru/index';

const messages = { en, fr, es, de, 'en-AU': enAU, 'en-UK': enUK, ru };

/* use en value if translate is not found */

/* eslint-disable-next-line no-restricted-syntax */
for (const [lang, list] of Object.entries(messages)) {
  messages[lang] = defaultsDeep(list, en);
}

export default messages;
