import ActionType from '../action-type';

export const Field = {
  Model: 'Model',
  Name: 'Name',
  Email: 'Email',
  Phone: 'Phone',
  DateOfBirth: 'DateOfBirth',
  Agreement: 'Agreement',
};

export const Step = {
  RegistrationForm: 'RegistrationForm',
  Success: 'Success',
  Fail: 'Fail',
  AlreadyRegistered: 'AlreadyRegistered',
};

export default function warrantyRegistrationForm(
  // eslint-disable-next-line default-param-last
  state = {
    model: '',
    name: '',
    email: '',
    phone: '',
    dateOfBirth: null,
    checked: false,
    isHasBeenSubmittedAtLeastOnce: false,
    invalidField: null,
    step: Step.RegistrationForm,
  },
  action
) {
  switch (action.type) {
    case ActionType.WarrantyRegistrationForm.SetModel:
      return { ...state, model: action.payload };
    case ActionType.WarrantyRegistrationForm.SetName:
      return { ...state, name: action.payload };
    case ActionType.WarrantyRegistrationForm.SetEmail:
      return { ...state, email: action.payload };
    case ActionType.WarrantyRegistrationForm.SetPhone:
      return { ...state, phone: action.payload };
    case ActionType.WarrantyRegistrationForm.SetDateOfBirth:
      return { ...state, dateOfBirth: action.payload };
    case ActionType.WarrantyRegistrationForm.ToggleChecked:
      return { ...state, checked: !state.checked };
    case ActionType.WarrantyRegistrationForm.SetHasBeenSubmittedAtLeastOnce:
      return { ...state, isHasBeenSubmittedAtLeastOnce: action.payload };
    case ActionType.WarrantyRegistrationForm.SetInvalidField:
      return { ...state, invalidField: action.payload };
    case ActionType.WarrantyRegistrationForm.SetStep:
      return { ...state, step: action.payload };
    default:
      return state;
  }
}
