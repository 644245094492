import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { WrapPopup, Image, Info, Links } from './styled';
import ToggleBtn from './ToggleBtn';
import useI18nNavigation from '../../../hooks/use-i18n-navigation';
import useSwitch from '../../../hooks/use-switch';
import { selectHasItemsLoaded } from '../../../store/selectors/items';

function Popup() {
  const location = useLocation();
  const { value: isOpen, turnOn, turnOff } = useSwitch(false);
  const navigate = useI18nNavigation();
  const hasItems = useSelector(selectHasItemsLoaded);

  useEffect(() => {
    if (!hasItems) return;

    const params = new URLSearchParams(location.search);
    if (!params.get('banner')) return;

    turnOn();
  }, [location, hasItems]);

  const handleGoToCheckout = () => {
    turnOff();
    navigate('/checkout');
  };

  const handleReadMore = () => {
    turnOff();
    navigate('/evalight');
  };

  return (
    <WrapPopup isOpen={isOpen}>
      <ToggleBtn onClick={turnOff} />
      <Image />
      <Info>
        <h4>
          <FormattedMessage id="checkout.banner_url.h4" />
        </h4>
        <h5>
          <FormattedMessage id="checkout.banner_url.h5" />
        </h5>
        <h3>
          <FormattedMessage id="checkout.banner_url.h3" />
        </h3>
        <Links>
          <button type="button" onClick={handleReadMore}>
            <FormattedMessage id="checkout.banner_url.learn_more_btn" />
          </button>
          <button type="button" onClick={handleGoToCheckout}>
            <FormattedMessage id="checkout.banner_url.go_cart_btn" />
          </button>
        </Links>
      </Info>
    </WrapPopup>
  );
}

export default Popup;
