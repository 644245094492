const EvapolarUpper = '<b>EVAPOLAR</b>';
const Evapolar = '<b>Evapolar</b>';
const EvapolarsUpper = '<b>EVAPOLAR&#39;S</b>';
const Evapolars = '<b>Evapolar&#39;s</b>';
const Evapolarss = '<b>Evapolars&#39;s</b>';
const Website = '<b>Website</b>';
const User = '<b>User</b>';
const Customer = '<b>Customer</b>';
const Product = '<b>Product</b>';
const Device = '<b>Device</b>';

module.exports = {
  'terms.title': `
    Terms of Service
  `,

  'terms.general.title': `
    General
  `,
  'terms.general.item1': `
    PLEASE READ THESE TERMS CAREFULLY, AS THEY CREATE A BINDING CONTRACT BETWEEN YOU AND ${EvapolarUpper} AND CONTAIN IMPORTANT PROVISIONS RELATED TO THE USE OF THE WEBSITE, PRIVACY POLICY AND TERMS OF SALE OF ${EvapolarUpper} PRODUCT. BY ACCESSING OR USING <b>WWW.EVAPOLAR.COM</b> WITH LINKS TO THESE TERMS IN ANY WAY, CLICKING ON THE “I ACCEPT” BUTTON, AND/OR BROWSING THE WEBSITE, YOU REPRESENT THAT (i) YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS AND (ii) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH ${EvapolarUpper}.
  `,

  'terms.general.item2': `${EvapolarUpper} RESERVES THE RIGHT TO CHANGE THESE TERMS WITHOUT ADVANCE NOTIFICATION AND CONSENT OF THE CUSTOMER. IT IS YOUR SOLE RESPONSIBILITY TO KEEP UP-TO-DATE WITH THE LATEST TERMS.`,

  'terms.general.item3': `THE TERM “YOU” REFERS TO AN INDIVIDUAL WHO USES THE WEBSITE AND PLACES ORDERS FOR ${EvapolarsUpper} PRODUCT FOR PERSONAL USE. IF YOU REPRESENT A LEGAL ENTITY OR ARE INTERESTED IN NON-PERSONAL USE OF ${EvapolarsUpper} PRODUCT, PLEASE CONTACT US AT SALES@EVAPOLAR.COM AND ${EvapolarUpper} WILL PROVIDE YOU WITH SPECIAL COMMERCIAL CONDITIONS.`,
  'terms.general.item4': `IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU MAY NOT ACCESS OR USE THE WEBSITE AND PLACE ORDERS FOR ${EvapolarsUpper} PRODUCT.`,

  'terms.definitions.title': `
    Definitions
  `,
  'terms.definitions.item1': `
    ${Evapolar} — Evapolar LTD, a legal entity organized and existing under the laws of the Republic of Cyprus and its affiliates, should any act as a vendor and/or a Website owner. Address: 236 Strovolou, Strovolos, 2048 Nicosia, Cyprus
  `,
  'terms.definitions.item2': `
    ${Website} — www.evapolar.com.
  `,
  'terms.definitions.item3': `
    ${User} — a person who accesses and uses the Website, regardless of the duration and results of such access and use. If otherwise not specified, ‘User’ means both a User and a Customer.
  `,
  'terms.definitions.item4': `
    ${Customer} – a User who initiates an Order formation on the Website.
  `,
  'terms.definitions.item5': `
    ${Product} – all goods presented for sale on the Website under the following Terms. If otherwise not specified, the ‘Product’ also means the Device.
  `,
  'terms.definitions.item6': `
    ${Device} – an air cooling device presented for sale on the Website under the following Terms.
  `,

  'terms.subtitle0': `
    Terms of Use
  `,

  'terms.eligibility.title': `
    User Eligibility
  `,
  'terms.eligibility.item1': `
    The User represents and warrants that he/she is (i) at least 18 years of age or the age of majority in his/her jurisdiction, whichever is greater; and (ii) of legal age to form a binding contract with ${Evapolar}. Otherwise, the User confirms that he/she has obtained the consent of a parent or guardian before accessing or using the Website. In all cases, the User represents and warrants that he/she is not a person barred from accessing or using the Website under the laws of his/her jurisdiction.
  `,

  'terms.international.title': `
    International Use
  `,
  'terms.international.item1': `
    ${Evapolar} controls and operates the Website from the Republic of Cyprus, and all information is processed within the Republic of Cyprus.
  `,
  'terms.international.item2': `
    ${Evapolar} does not represent that materials on the Website are appropriate or available for use in other locations. The User agrees to comply with all applicable laws, rules and regulations in connection with the use of the Website.
  `,

  'terms.restrictions.title': `
    User Conduct and Restrictions of Use
  `,

  'terms.restrictions.item1': `
    In addition to other restrictions set forth in these Terms, the User agrees that he/she will not use the Website to take any action and/or make available any content that (i) infringes or violates the intellectual property rights or proprietary rights, rights of publicity or privacy, or other rights of any third party; (ii) violates any law, statute, ordinance or regulation; (iii) is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, or profane; (iv) constitutes unauthorized or unsolicited advertising, junk, or bulk e-mail; (v) involves commercial activities and/or sales without ${Evapolars} prior written consent, such as contests, sweepstakes, barter, advertising, or pyramid schemes; (vi) impersonates any person or entity, including without limitation any employee or representative of ${Evapolar} ; (vii) contains a virus, trojan horse, worm, time bomb, or other harmful computer code, file, or program; (viii) attempts, in any manner, to obtain security information from any other User; (ix) violates the security of any computer network, or cracks any passwords or security encryption codes.
  `,
  'terms.restrictions.item2': `
    The User is solely responsible for their communications and interactions with other Users. ${Evapolar} reserves the right, but has no obligation, to intercede in any disputes.
  `,
  'terms.restrictions.item3': `
    Certain areas of the Website are restricted to Users.
  `,
  'terms.restrictions.item4': `
    ${Evapolar} reserves the right to seek all remedies available at law and in equity for violations of these Terms, including the right to block access from a particular Internet address to this Website.
  `,

  'terms.noEndorsement.title': `
    No Endorsement
  `,
  'terms.noEndorsement.item1': `
    ${Evapolar} does not control User Content posted on the Website and, as such, does not make any representations or warranties regarding User Content or its truthfulness, accuracy, reliability, integrity or quality. ${Evapolar} does not determine whether User Content violates the rights of others, and has no control over whether User Content is of a nature that Users may find offensive.
  `,
  'terms.noEndorsement.item2': `
    The User acknowledges and agrees that the information and views expressed by Users in User Content appearing on the Website do not necessarily reflect ${Evapolarss} views or those of its content providers, advertisers, sponsors, affiliated or related entities, and do not support or endorse any User Content or any other content posted by any User on or otherwise accessible through the Website. ${Evapolar} does not assume, and expressly disclaim, any obligation or liability with respect to User Content and no confidential or fiduciary understanding or relationship is established by ${Evapolars} receipt or acceptance of such materials.
  `,

  'terms.enforcement.title': `
    Enforcement
  `,
  'terms.enforcement.item1': `
    ${Evapolar} is not obligated to monitor access or use of the Website or User Content or to review or edit any User Content, but reserves the right to do so to ensure compliance with these Terms and with applicable laws or other legal requirements.
  `,
  'terms.enforcement.item2': `
    ${Evapolar} may consult with and disclose unlawful conduct to law enforcement authorities. Pursuant to valid legal process, ${Evapolar} may cooperate with law enforcement authorities to prosecute Users who violate the law.
  `,
  'terms.enforcement.item3': `
    ${Evapolar} reserves the right, but are not required, to remove or disable access to the User Content at any time and without notice, and at its sole discretion, if ${Evapolar} determines that the User Content is objectionable or in violation of these Terms. ${Evapolar} has a right to investigate violations of these Terms and any conduct that affects ${Evapolar}.
  `,

  'terms.links.title': `
    Third Parties Websites and Links
  `,
  'terms.links.item1': `
    The Website may contain links to other websites operated by third parties. These links are provided for Users’ information and convenience only and are not an endorsement by ${Evapolar}. ${Evapolar} has no control over the linked websites, the third parties they are owned and operated by, the information contained on them or the availability, suitability or quality of any of their products or services.
  `,
  'terms.links.item2': `
    By accessing any third party website, the User acknowledges that he/she does so at his/her own risk and agrees that ${Evapolar} shall have no liability arising from the use of or access to any third party website, service, or content.
  `,

  'terms.advertisements.title': `
    Advertisements, co-promotions and other partnerships
  `,
  'terms.advertisements.item1': `
    ${Evapolar} may display advertisements for the goods and services of a third party on the Website, including in connection with co-promotions or other similar partnership arrangements. ${Evapolar} does not endorse or represent and is not responsible for the safety, quality, accuracy, reliability, integrity or legality of any such advertised goods or services.
  `,

  'terms.subtitle1': `
    Terms of Sale
  `,

  'terms.acceptance.title': `
    Offer and Acceptance
  `,
  'terms.acceptance.item1': `
    Information related to the Product displayed on the Website by ${Evapolar} and these Terms of Sale is an offer by ${Evapolar} to sell the Product to every Customer who is interested in purchasing the Product. The offer is subject to these Terms. By placing an Order on the Website the Customer accepts the Terms of Sale and enters into a legally binding purchase contract with ${Evapolar}.
  `,
  'terms.acceptance.item2': `
    The Customer will get confirmation of payment right after payment via email, which would be specified as a contact address.
  `,
  'terms.acceptance.item3': `
    ${Evapolar} disagrees with any additional or different terms proposed in any form by the Customer.
  `,
  'terms.acceptance.item4': `
    The cancellation requests are accepted no later than 30 minutes after the order placement. No Order may be canceled or altered by the Customer after it has already been shipped, except in accordance with these Terms of Sale or with ${Evapolars} written consent.
  `,
  'terms.acceptance.item5': `
    ${Evapolar} reserves the right to change these Terms of Sale without advance notification and consent of the Customer at any time prior to the placement of an Order. The Customer is solely responsible for keeping up-to-date with the latest Terms of Sale by checking back before placing a new Order. Every new Order placed by the Customer on the Website signifies that the Customer acknowledges and agrees to all the changes to the Terms of Sale. Any changes to the Terms of Sale made after the placement of an Order affect the Customer only in case of his/her consent.
  `,

  'terms.specifications.title': `
    The Device (Specifications)
  `,
  'terms.specifications.item1': `
    The Device is intended to be used as a personal air cooling and moderate air hydration system. The working Device affects a limited indoor space. The Device has no functionality of an air purifier or any other characteristics except for explicitly expressed in these Terms of Sale and/or instruction manuals and other accompanying materials received by the Customer with the delivery of the Device.
  `,
  'terms.specifications.item2': `
    The cooling power of the Device depends on the existing external parameters of air and placement. The maximum cooling power is 450 W and is reached at an ambient temperature of 40° C and 10% humidity.
  `,
  'terms.specifications.item3': `
    All pictures and images of the Device displayed on the Website are for informational purposes only and represent the model design of the Device. ${Evapolar} is permanently working on improvement of the exterior design and internal construction of the Device. In light of this, ${Evapolar} reserves the right to make changes to the exterior design and/or internal construction, specifications or materials used, which in ${Evapolars} opinion provide improvement to the Device’s technical or usability characteristics and/or necessary because of priorities and regulations established by authorities or non-availability of materials from suppliers.
  `,
  'terms.specifications.item4': `
    All changes to the Device by ${Evapolar} including exterior design changes with retention of technical characteristics and functionality of the Device are at the sole discretion of ${Evapolar} and do not create grounds for return of the Device to ${Evapolar}. The right to return or exchange the Device with a significantly changed exterior design can be exercised in accordance with the Return Policy expressed in Section 16 of these of Terms of Sale.
  `,

  'terms.device.title': `
    Use of Device
  `,
  'terms.device.item1': `
    The Device intended to be used as a personal air cooling and moderate air hydration system in accordance with these Terms and/or instruction manuals and other accompanying materials received by the Customer with the delivery of the Device . Other uses may not render the same results as described in Section 11 of these Terms of Sale and may be potentially harmful to the Consumer and/or third parties’ health and property, as well as for the environment.
  `,
  'terms.device.item2': `
    The Device is intended for indoor use only. The Customer could also use the Device in enclosed outdoor spaces like tents or vehicles at his/her own discretion but with recognition that ${Evapolar} gives no warranties on proper functioning of the Device in case of the outdoor placement and shall not be liable for any damage resulting from the outdoor-use of the Device.
  `,

  'terms.warranty.title': `
    Warranty
  `,
  'terms.warranty.item1': `
    ${Evapolar} warrants the Product's level of quality and functionality necessary for the proper use of the Product within the parameters and characteristics described in these  Terms of Sale and/or instruction manuals and other accompanying materials received by the Customer with the delivery of  the Product. Such warranty is for the period of one year following the date of delivery. Pursuant to the legal guarantee concerning product conformity, ${Evapolar} commits to delivering the Product in accordance with these Terms of Sale.
  `,
  'terms.warranty.item2': `
    ${Evapolar} represents and warrants that (i) the Product is an original and (ii) ${Evapolar} has full power and authority to sell products. ${Evapolar} also takes responsibility for any latent defects of the Product noticed upon delivery.
  `,
  'terms.warranty.item3': `
    The foregoing warranties do not cover the situations involving (i) deterioration resulting from any modification or adjustments (other than those required for the Product to operate) made without written authorization by ${Evapolar}; (ii) deterioration resulting from incorrect use, including (a) inappropriate use of the Product or non-respect to ${Evapolars} instructions on how to use and maintain the Product, (b) damages resulting from repairs by non-certified organizations, by Customer’s themselves or third parties, (c) damages caused by accidents, lightning, floods, insufficient air flow or any other reasons for which ${Evapolar} is not responsible.
  `,
  'terms.warranty.item4': `
    THE FOREGOING WARRANTIES ARE EXCLUSIVE AND IN LIEU OF ALL OTHER EXPRESSED AND IMPLIED WARRANTIES WHATSOEVER, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR PARTICULAR PURPOSE.  This exclusive remedy will not be deemed to have failed its essential purpose so long as ${Evapolar} is willing and able to replace defective products within a reasonable time after the Customer informs ${Evapolar} that a defect is involved.
  `,
  'terms.warranty.item5': `
    Warranty does not cover consumable parts, including cartridge, unless damage is due to defects in the materials or workmanship of the Product.
  `,
  'terms.warranty.item6': `
    Conditions set forth in this Section are applied only to the Product purchased through this Website. Warranties for a Product that has been purchased through other means of sale, including from our distributors, are specified by a separate agreement concluded at the moment of such purchase.
  `,

  'terms.payment.title': `
    Price and Payment
  `,
  'terms.payment.item1': `
    The price of the Product indicated on the Website is shown in US Dollars or in other currency which is (i) chosen by the Customer or (ii) in any local currency which is mandatory to be used under national law of the Customer’s geographical location and does not include delivery fees and applicable taxes. ${Evapolar} reserves the right to change the price of the Product at any time before the Order is placed. If an obviously incorrect price appears on the Website and is not corrected at the time of the placement of the Order, for any reason (human or technical error etc.), the Order will be canceled and the payment (if it has been validated) will be refunded.
  `,
  'terms.payment.item2': `
    The delivery fees and applicable taxes are shown before the placement of the Order and are to be paid at the same time with the price of the Product as a total payment, which is invoiced to the Customer on the Website. The delivery fees could depend on the particularities of delivery in accordance with Section 15 of these Terms of Sale.
  `,
  'terms.payment.item3': `
    The total payment invoiced to the Customer on the Website could be paid with a (i) credit/debit card or (ii) through a PayPal account. The Payment provider of the chosen payment method could govern the payment procedure by its own regulations, terms and conditions. ${Evapolar} is not responsible for any rights and liabilities both of the Customer or the Payment Provider during the payment procedure under the mentioned regulations, terms and conditions.
  `,
  'terms.payment.item4': `
    The Customer agrees that provided details of credit/debit cards or payment accounts may be processed and used by ${Evapolar} and (or) any third party involved in the payment processing after the placement of the Order.
  `,

  'terms.delivery.title': `
    Product Delivery
  `,
  'terms.delivery.item1': `
    ${Evapolar} undertakes to deliver the Product to the address indicated by the Customer within 21 (twenty one) business days following the acceptance of total payment. Designated delivery period is approximate and could be extended by ${Evapolar} due to ${Evapolars} reasonable business circumstances, but no more than for 21 (twenty one) business days. In case the Product was pre-ordered, which was indicated to the Customer during the formation of the order, ${Evapolar} will notify the Customer about any possible delay in delivery of the Product. The delivery time in this case will be limited to 60 (sixty) days.
  `,
  'terms.delivery.item2': `
    ${Evapolar} shall not be liable for any damage as a result of any delay due to any cause beyond ${Evapolars} reasonable control, including but not limited to any act of God, act or mistake by the Customer, embargo or other governmental act, regulation or request, accident, strike, war, riot, delay in transportation and delivery, delayed delivery by suppliers. In the event of any such delay, the date of delivery will be extended for a period equal to the time lost by reason of the delay.
  `,
  'terms.delivery.item3': `
    ${Evapolar} reserves the right to request additional information from the User in case of any doubt about order and/or transaction made by the User. In such case delivery period shall begin from the day of receipt of all necessary additional information requested by ${Evapolar}.
  `,

  'terms.refund.title': `
    Return, Refund and Exchange Policy
  `,
  'terms.refund.item1': `
    Claims for defects of the Product which are observable when the Product is received by the Customer must be presented in writing to  ${Evapolar} within 15 (fifteen) days after delivery. Failure to give such notice will constitute unqualified acceptance and a waiver of all such claims by the Customer. Upon proper notification of  ${Evapolar}, the defected Product will be replaced by  ${Evapolar} at its own cost.
  `,
  'terms.refund.item2': `
    Claims for defects of functionality of the Product must be presented in writing to  ${Evapolar} within the period designated in provisions for the Warranty, expressed in Section 13 of these Terms of Sale (if the Customer finds any). This defected Product will be repaired by  ${Evapolar} at its own cost.
  `,
  'terms.refund.item3': `
    If the received Product contains modifications that significantly affect the Product's exterior design and/or dimensions, the Customer must notify  ${Evapolar} in writing within 15 (fifteen) days after delivery. Failure to give such notice will constitute unqualified acceptance and a waiver of all such claims by the Customer. Upon proper notification of  ${Evapolar} such Product could be returned by the Customer to  Evapolar.
  `,
  'terms.refund.item4': `
    The Product claimed under p. 16.1- will be replaced by  ${Evapolar} within 40 days following the date of receiving the Claimed Product from the Customer by  ${Evapolar}. The Customer must fill in  the Replace Form provided by  ${Evapolar} upon the Customer’s request via <a href="https://support.evapolar.com" target="_blank" rel="noopener noreferrer">support.evapolar.com</a> and sends the Claimed Product by a registered delivery services provider at his/her own cost.  ${Evapolar} will not be liable for any losses and damages caused by actions of delivery services provider chosen by the Customer to return the Claimed Product. The right to replace the Product only applies to the Product that is returned in its original, complete condition (packaging, accessories, user guide etc.). Any Product that is not in its original packaging, or has packaging that has been worn beyond simply opening the product, will not be replaced.
  `,
  'terms.refund.item5': `
    The Product claimed under p. 16.2 will be repaired by  ${Evapolar} within 40 days following the date of receiving the Claimed Product from the Customer by  ${Evapolar}. The Customer must fill in  the Service Repair Form provided by  ${Evapolar} upon Customer’s request via <a href="https://support.evapolar.com" target="_blank" rel="noopener noreferrer">support.evapolar.com</a> and sends the Claimed Product by a registered delivery services provider at his/her own cost.  ${Evapolar} will not be liable for any losses and damages caused by actions of the delivery services provider chosen by the Customer to return the Claimed Product.
  `,
  'terms.refund.item6': `
    The Product claimed under p. 16.3 could be returned by the Customer to  ${Evapolar} upon proper notification of  ${Evapolar} in accordance with p.16.3 of these Terms of Sale. The Customer must fill in  the Return Form provided by  ${Evapolar} upon the Customer’s request via <a href="https://support.evapolar.com" target="_blank" rel="noopener noreferrer">support.evapolar.com</a> and sends the Claimed Product by a registered delivery services provider at his/her own cost.  ${Evapolar} will not be liable for any losses and damages caused by actions of the delivery services provider chosen by the Customer to return the Claimed Product. The right to return the Product only applies to the Product that is returned in its original, complete working condition (packaging, accessories, user guide, etc.). Any Product that has been damaged, is not in its original packaging, or has packaging that has been worn beyond simply opening the product, will not be accepted and refunded by  ${Evapolar}.
  `,
  'terms.refund.item7': `
    The Customer will be reimbursed for the returned Product within 30 days following the date of receiving the Claimed Product by ${Evapolar} via the same payment method that was used by the Customer for the initial payment.
  `,
  'terms.refund.item8': `
    The Customer is entitled to return the Product in any case during 14 days following the date of receiving the Product. The necessary steps: <ol type="a"><li>The Customer is obliged to provide Evapolar with a proof of purchase of the Product from Evapolar.</li><li>The Customer writes a letter about such return via <a href="https://support.evapolar.com">support.evapolar.com</a> and sends the Product by a registered delivery services provider at his/her own cost. Return Tracking Number and delivery services provider website link must be provided to monitor delivery status.</li><li>Shipping and handling costs, gift box costs and other charges are non-refundable.</li><li>All return shipping charges must be prepaid. Evapolar cannot accept COD deliveries.</li><li>The Customer will be reimbursed for the returned Product (excluding customs duties and taxes, <span style="color: red;">restocking charge of $5-10 (depending on the model)</span> and shipping costs of ${Evapolar}) within 30 days following the date of receiving the Product by ${Evapolar} via the same payment method that was used by the Customer for the initial payment.</li></ol>The right to return the Product with reasonable <span style="color: red;">restocking charge of $5-10 (depending on the model)</span> only applies to the Product that is returned in its original, complete condition (packaging, accessories, user guides etc.). Restocking charge is higher for returns that are late, heavily used, damaged or missing parts. Shipping cost is not refundable. Any Product that is not in its original packaging, or has packaging that has been worn beyond simply opening the product, will not be accepted by Evapolar.
  `,
  'terms.refund.item9': `
    If during the Warranty Period the Software does not perform properly, EVAPOLAR shall correct the relevant Software giving rise to such breach of performance or replace such Software free of charge. The Product itself can be returned or replaced only in cases mentioned above. THE FOREGOING ARE CUSTOMER'S SOLE AND EXCLUSIVE REMEDIES FOR BREACH OF OR NONCOMPLIANCE WITH THE FOREGOING WARRANTY. THE WARRANTY SET FORTH ABOVE IS MADE TO AND FOR THE BENEFIT OF CUSTOMER ONLY. The warranty will apply only if (i) the Software has been used at all times and in accordance with the instructions for use set forth in the supporting documents; (ii) no modification, alteration or addition has been made to the Software by persons other than EVAPOLAR or EVAPOLAR's authorized representative; and (iii) the Software or Product on which the Software is installed has not been subject to any unusual electrical charge.
  `,
  'terms.refund.item10': `
    In case of any request/claim via <a href="https://support.evapolar.com" target="_blank" rel="noopener noreferrer">support.evapolar.com</a> the User agrees and is obliged to provide Evapolar's customer care officer with any information/documents/evidences (including, but not limited with, photos, videos) for the propose of resolving such request/claim.
  `,

  'terms.policy.title': `
    Privacy policy
  `,
  'terms.policy.item1': `
    ${Evapolar} collects Personal Data (information that specifically identifies an individual, such as their name, address, telephone number, mobile number or e-mail address or other information about that individual that is directly linked to Personal Data) for the purpose of processing and delivery of the User’s Order, for billing and for establishment of provisions of these Terms (including but not limited to provisions related to warranties, returns and exchanges). By placing the Order the User has agreed to provide ${Evapolar} with actual and accurate information required for the purposes mentioned above.
  `,
  'terms.policy.item2': `
    ${Evapolar} does not share Personal Data of the User with third parties, except for those who are engaged in the processing and delivery of the User’s Order, billing and establishment of provisions of these Terms.
  `,
  'terms.policy.item3': `
    ${Evapolar} reserves the right to disclose Personal Data (i) as required by law and when ${Evapolar} reasonably assumes this disclosure is necessary to protect ${Evapolars}rights; (ii) to comply with judicial proceedings, court orders or legal process served on ${Evapolar} or the Website; (iii) in connection with an actual or proposed corporate transaction or insolvency proceeding involving all or a part of the business or asset to which the Personal Data pertains.
  `,
  'terms.policy.item4': `
    ${Evapolar} may also track certain non-personal data related to the User’s use of the Website by way of using different technologies (such as Cookies, log files or web bugs) for statistical, internal analytics and research purposes. ${Evapolar} may share such non-personal data in accordance with its business needs.
  `,
  'terms.policy.item5': `
    ${Evapolars} Privacy Policy covers only information that is collected on the Website and does not cover any website to which it may link.
  `,
  'terms.policy.item6': `
    ${Evapolar} may use an e-mail provided by the User for notification, security, support and maintenance advisories, newsletters, surveys, offers, and other promotional materials related to the Product or changes to these Terms. ${Evapolar} provides the User with the right to “opt-out” of using Personal Data by ${Evapolar} for promotional communication by sending a request via <a href="https://support.evapolar.com" target="_blank" rel="noopener noreferrer">support.evapolar.com</a>.
  `,
  'terms.policy.item7': `
    ${Evapolar} may receive comments and feedbacks related to the Product or the Website from Users and place it on the Website or other sources for promotional purposes and other ${Evapolars} business needs. For publishing such content ${Evapolar} may identify the authors by their first and last name and may also indicate their home city. By providing such comments and feedback the User gives ${Evapolar} consent to post his or her name along with the comment or feedback.
  `,

  'terms.property.title': `
    Intellectual Property
  `,
  'terms.property.item1': `
    All intellectual property rights, including without limitation copyrights, patents, trademarks, and trade secrets of the Product and the Website are owned by ${Evapolar} or its affiliates and business partners and protected by international law concerning intellectual ownership and copyright and applicable national laws. Possession, access, and use of the Product or the Website by the User do not transfer to the User or any third party any rights, title, or interest in or to such intellectual property rights, including but not limited to rights to copy, display or distribute content that the User does not have intellectual rights to. ${Evapolar} and its affiliates and business partners reserve all the rights not granted in these Terms.
  `,
  'terms.property.item2': `
    The User is permitted to share on the Website through available means certain ideas, data, information, opinions, comments on the Products, image, audio, and video content or any other materials (collectively, “User Content”).
  `,
  'terms.property.item3': `
    The User represents and warrants that he/she owns or otherwise controls any and all the rights in and to the User Content that he/she submits and that public posting and use of that User Content will not infringe or violate the rights of any third party in any manner, including without limitation any privacy rights, publicity rights, copyrights, contract rights or any other intellectual property or proprietary rights.
  `,
  'terms.property.item4': `
    By displaying the User Content the User grants Evapolar, its affiliates, licensees the royalty-free, fully paid, unrestricted, worldwide, perpetual, irrevocable, non-exclusive, and fully transferable, assignable, sublicensable right and license to use, copy, reproduce, modify, adapt, publish, translate, create derivative works from, improve, distribute, commercialize, perform, or display such User Content (in whole or in part) worldwide and/or to incorporate it into other works in any form, media, or technology now known or later developed for any purpose, including, without limitation, advertising and promotional purposes, reproduction, transmission, publication, broadcast and posting without any further consent by the User or notice, credit and/or compensation to the User or any third parties.
  `,
  'terms.property.item5': `
    The User also hereby does and shall grant each ${Evapolar} a non-exclusive license to access the User Content through the Website, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Content.
  `,
  'terms.property.item6': `
    If the User has an idea or information that he/she would like to keep confidential and/or does not want others to use, he/she should not post it on the Website. ${Evapolar} is not responsible for any User’s misuse or misappropriation of any User Content posted on the Website.
  `,
  'terms.property.item7': `
    Personally identifiable data, if any, included in User Content shall be handled in accordance with the Privacy Policy.
  `,

  'terms.liability.title': `
    Limitation of Liability
  `,
  'terms.liability.item1': `
    ${Evapolar} is not responsible and shall not be liable for any loss, damage, loss of profits, claim or injury, whether direct or indirect, of any kind, which has a connection with the use of, or interruptions in the use of, the Product.
  `,
  'terms.liability.item2': `
    This includes without limitation (i) exposure to water (except for properly placed liquid necessary for the functioning of the Product), sunlight, heat, dust and other natural and/or industrial factors; (ii) placing foreign matter inside the Product (except for liquid necessary for the functioning of the Product); (iii) disassembling the Product (except for cleaning purposes specified in the instruction manuals and other accompanying materials received with the delivery of  the Product); (iv) limiting air access to the ventilating parts of the Product; (iv)  placing anything on top of the Product and/or sitting and standing on the Product; (v) filling the Product with any other liquids except for the liquid necessary for the functioning of the Product (defined in these Terms or instruction manuals and other accompanying materials received with the delivery of  the Product); (vi) throwing, punching, kicking or performing any other physical activity on/with the Product; (vii) leaving children and/or pets unattended in close proximity to the Product; (viii) using the Product in contradiction with its intended use; (ix) violating any regulations related to the use of the Product expressed in these Terms and instruction manuals and other accompanying materials received with the delivery of  the Product.
  `,
  'terms.liability.item3': `
    ${Evapolar} is not responsible and shall not be liable for any actions of third parties, including, but not limited to, property owners and electricity providers.
  `,

  'terms.indemnity.title': `
    Indemnity
  `,
  'terms.indemnity.item1': `
    By using the Website and/or the Product, the User agrees to defend, indemnify, and hold Evapolar, its affiliates, and each of their respective officers, directors, employees, contractors and suppliers harmless from any and all claims, liabilities, damages, losses, costs, and expenses, including, but not limited to, attorney’s’ fees and expenses, arising in any way from or in connection with User’s use of the Website and/or the Product, the uploading, posting, e-mailing, or transmission of any User Content or other materials by you or users authorized by User, infringement of any proprietary material, or any violation by the User of these Terms, the Privacy Policy or any other policy posted from time to time on the Website applicable to the use of the Website and/or the Product.
  `,
  'terms.indemnity.item2': `
    ${Evapolar} reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by the User, in which event he/she agrees to assist and cooperate with Evapolar in asserting any available defenses.
  `,

  'terms.severability.title': `
    Severability
  `,
  'terms.severability.item1': `
    If one or more provisions of these Terms is deemed invalid or declared invalid pursuant to a law, regulation or following a definitive decision by a court having jurisdiction, the other provisions will retain their full force and scope.
  `,

  'terms.law.title': `
    Governing Law, Jurisdiction and Dispute Resolution
  `,
  'terms.law.item1': `
    These Terms, whether interpreted in a court of law or in arbitration, shall be governed by  the laws of the State of New York, the United States of America as they apply to agreements entered into and to be performed entirely for the User’s interest.
  `,
  'terms.law.item2': `
    The courts in some countries will not apply <b><em>the laws of the State of New York, the United States of America</em></b> to some types of disputes. If the User resides in one of those countries, then where <b><em>the laws of the State of New York, the United States of America</em></b> will not be applied in accordance with national law, the laws of the country of the User’s residence will apply to such disputes related to these Terms. Otherwise, the User agrees that these Terms, and any claim, dispute, action, cause of action, issue, or request for relief arising from or relating to these Terms or use of the Product and the Website will be governed by <b><em>the laws of the State of New York, the United States of America</em></b> without giving effect to any conflict of laws principles that may provide the application of the law of another jurisdiction, as far as those provisions are not in  contradiction with imperative rules of applicable jurisdiction.
  `,
  'terms.law.item3': `
    If a dispute arises between the User and ${Evapolar}, the User shall notify ${Evapolar} about any dispute he/she has with ${Evapolar} regarding these Terms or Product and/or the Website by contacting ${Evapolar}. The Customer agrees to submit to the personal jurisdiction of the <b><em>state and federal courts located in the State of New York</em></b> for the purpose of litigating all such claims or disputes, unless such claim or dispute is required to be arbitrated in another court in accordance with imperative rules of another jurisdiction.
  `,
};
