import _ from 'lodash';

import list from '../../domain/plugs';

const countries = [
  {
    list: [
      'AD',
      'AL',
      'AM',
      'AT',
      'BA',
      'BE',
      'BG',
      'BY',
      'CH',
      'CZ',
      'DE',
      'DK',
      'EE',
      'ES',
      'FI',
      'FO',
      'FR',
      'GE',
      'GG',
      'GI',
      'GL',
      'GP',
      'GR',
      'HR',
      'HU',
      'IE',
      'IS',
      'IT',
      'JE',
      'LI',
      'LT',
      'LU',
      'LV',
      'MC',
      'MD',
      'ME',
      'MK',
      'NL',
      'NO',
      'PL',
      'PT',
      'RE',
      'RO',
      'RS',
      'RU',
      'SE',
      'SI',
      'SK',
      'SM',
      'TR',
      'UA',
      'VA',
      'XK',
    ],
    default: ['EU', 'EU/UK'],
  },
  {
    list: ['GB', 'CY', 'MT'],
    default: ['UK', 'EU/UK'],
  },
  {
    list: ['US', 'CA', 'JP'],
    default: ['US'],
  },
];

const selectCountries = (state) => state.countries;

export const selectPickedCountryCode = (state) => selectCountries(state).picked;

export const selectCountryPlugs = (state) => {
  const code = selectPickedCountryCode(state);
  const entry = countries.find((country) => country.list.includes(code));

  return entry ? entry.default : [];
};

export const selectSortedPlugs = (state) => {
  const countryPlugs = selectCountryPlugs(state);

  return _.union(countryPlugs, list);
};
