import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './src/store';
import PreviewPage from './src/components/preview-page';
import isServerRender from './src/utils/isServerRender';

// eslint-disable-next-line react/prop-types
function Wrapper({ element }) {
  return isServerRender() ? (
    element
  ) : (
    <PersistGate loading={<PreviewPage redir="" />} persistor={persistor}>
      {element}
    </PersistGate>
  );
}

// eslint-disable-next-line react/prop-types
const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <Wrapper element={element} />
  </Provider>
);

export default wrapRootElement;
