import * as ItemsSelectors from '../selectors/items';
import ActionType from '../action-type';
import * as dataLayer from '../../utils/datalayer/dataLayer';

export default function createRemoveItemThunk(payload) {
  return async function removeItemThunk(dispatch, getState) {
    const itemList = ItemsSelectors.selectItemList(getState());

    const item = itemList.find((value) => value.id === payload.id);
    dataLayer.removeFromCart(item, payload.count);
    dispatch({ type: ActionType.Basket.RemoveItem, payload });
  };
}
