import {
  getItemColorName,
  getItemName,
  getItemPlug,
  getItemPreviewImage,
  getItemPrice,
  getItemPriceCurrency,
  getItemRemaindersQuantity,
  isItemDevice,
  getItemDeviceForPackage,
  getItemPackage,
} from '../../utils/api/items';

const DEVICE_ID = 0;
const PACKAGE_ID = 1;

function getAttributes(deviceItem) {
  const color = getItemColorName(deviceItem);
  const plug = getItemPlug(deviceItem);
  const attributes = {};
  if (color) {
    attributes.color = color;
  }
  if (plug) {
    attributes.plug = plug;
  }
  return attributes;
}

function createBaseCartItem(item) {
  return {
    id: item.id,
    price: getItemPrice(item),
    currency: getItemPriceCurrency(item),
    name: getItemName(item),
    img: getItemPreviewImage(item),
    availableQuantity: getItemRemaindersQuantity(item),
    count: 1,
    dual: false,
    attributes: {},
    isAlternated: false,
    productType: item.product.types[0].code,
  };
}

function isAlternated(currIdx) {
  return currIdx !== DEVICE_ID;
}

export const createAccessoryCartItem = (item) => createBaseCartItem(item);

const createCartSubitemForDevice = (item) => ({
  id: item.id,
  price: getItemPrice(item),
  availableQuantity: getItemRemaindersQuantity(item),
  currency: getItemPriceCurrency(item),
  productType: item.product.types[0].code,
  img: getItemPreviewImage(item),
});

export const createDevicePackageCartItem = (item) => {
  const isDevice = isItemDevice(item);
  const deviceItem = isDevice ? item : getItemDeviceForPackage(item);
  const packageItem = isDevice ? getItemPackage(item) : item;
  const attributes = getAttributes(deviceItem);
  const currentIdx = isDevice ? DEVICE_ID : PACKAGE_ID;
  const deviceCartItem = {
    ...createCartSubitemForDevice(deviceItem),
    name: getItemName(deviceItem),
  };
  const packageCartItem = {
    ...createCartSubitemForDevice(packageItem),
    name: `${deviceCartItem.name} + Cartridge`,
  };
  const items = [deviceCartItem, packageCartItem];
  const difference = packageCartItem.price - deviceCartItem.price;
  return {
    ...items[currentIdx],
    attributes,
    img: getItemPreviewImage(item),
    count: 1,
    dual: true,
    items,
    currentIdx,
    difference,
    isAlternated: isAlternated(currentIdx),
    productType: item.product.types[0].code,
  };
};
