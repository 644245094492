import accessibility from './accessibility';
import accessories from './accessories.json';
import affiliate from './affiliate.json';
import all from './all.json';
import apps from './apps.json';
import backend from './backend.json';
import basket from './basket.json';
import benefits from './benefits.json';
import blog from './blog.json';
import brandnew from './brandnew.json';
import checkout from './checkout.json';
import cookies from './cookies.json';
import coolPrivilege from './cool-privilege.json';
import currencies from './currencies.json';
import difference from './difference.json';
import evaChillBlue from './evachill-blue.json';
import family from './family.json';
import faq from './faq';
import features from './features.json';
import footer from './footer.json';
import headings from './headings.json';
import loading from './loading';
import meta from './meta.json';
import modelComparison from './model-comparison.json';
import nav from './nav.json';
import newMeta from './new-meta.json';
import newNav from './new-nav.json';
import page from './page.json';
import privacy from './privacy';
import productsFaq from './products-faq.json';
import promo from './promo.json';
import reasonsCompare from './reasons-compare.json';
import shop from './shop.json';
import specifications from './specifications.json';
import technology from './technology.json';
import technologyFaq from './technology-faq.json';
import aboutUs from './about-us.json';
import techPage from './techPage.json';
import terms from './terms';
import productGuides from './product-guides';
import warranty from './warranty.json';

export default {
  ...accessibility,
  ...accessories,
  ...affiliate,
  ...all,
  ...apps,
  ...backend,
  ...basket,
  ...benefits,
  ...brandnew,
  ...checkout,
  ...coolPrivilege,
  ...cookies,
  ...currencies,
  ...difference,
  ...evaChillBlue,
  ...family,
  ...faq,
  ...features,
  ...footer,
  ...headings,
  ...loading,
  ...meta,
  ...modelComparison,
  ...nav,
  ...newMeta,
  ...newNav,
  ...page,
  ...privacy,
  ...productsFaq,
  ...promo,
  ...reasonsCompare,
  ...shop,
  ...specifications,
  ...technology,
  ...technologyFaq,
  ...aboutUs,
  ...techPage,
  ...terms,
  ...blog,
  ...productGuides,
  ...warranty,
};
