module.exports = {
  'faq.title': `
    Haben Sie eine Frage?
  `,

  'faq.text': `
   Lesen Sie die Antworten auf FAQ
   oder stellen Sie Ihre eigene Frage.
  `,

  'faq.question1': 'Wie funktioniert Evapolar?',
  'faq.answer1': `
    Evapolar ist ein portabeles persönliches Gerät, das nicht nur kühlt, sondern auch befeuchtet und reinigt die Luft um Sie herum. Das Funktionsprinzip ist sehr einfach: einfach den Wassertank zu füllen und Evapolar anzuschließen. Innerhalb von 2-3 Minuten absorbiert der Evapolar Filter eine große Wassermenge. Das Wasser verteilt sich dann gleichmäßig über die Oberfläche der Kühlkissen. Wenn die Luft durch die Kissen zirkuliert, verdampft das Wasser, das die Luft abkühlen lässt, während Sie mit Wasser gesättigt wird. Der eingebaute Lüfter hilft, die kühle Luft auf einer Oberfläche von 3-4 m2 um Sie herum zu verbreiten. Mit der Kraftsteuerung können Sie den Kühleffekt einstellen und so die angenehmsten thermischen Bedingungen für Sie schaffen.
  `,

  'faq.question2':
    'Was ist der Unterschied zwischen Evapolar und anderen Kühlgeräten?',
  'faq.answer2': `
    Evapolar übertrifft andere Kühlgeräte in vielen Aspekten.
    Zuallererst ist Evapolar ein persönliches Gerät.
    Es kann Ihnen helfen, Ihr eigenes bequemstes Mikroklima zu schaffen,
    ohne in den persönlichen Raum der Menschen um Sie herum einzudringen.
    Zweitens nennen wir Evapolar eher ein "Klimagerät"
    als "Luftkühler" aufgrund seiner Effizienz im Vergleich zu
    "traditionellen" Klimageräte. Evapolar bleibt in der Preisklasse der preisgünstigen
    Tischplattegeräte, erfordert keine Installationskosten
    und ist sofort einsatzbereit.
  `,
  'faq.answer2.2': `
    Drittens kühlt Evapolar die Luft durch verdampftes Wasser ab,
    was das Gerät auch ein effizienter Luftbefeuchter macht.
    Sie müssen keinen separaten Lüfter, Luftbefeuchter oder Reiniger
    kaufen. Unsere Technologie vereint alle drei Funktionen
    zu einem Gerät. So schafft Evapolar ein sogenanntes
    "persönliches Mikroklima." Und zu guter Letzt,
    verbraucht Evapolar 100-Mal weniger Energie als normales geteiltes System
    - nur 10W – was sich eindeutig positiv
    auf Ihre Stromrechnung auswirkt.
  `,

  'faq.question3': 'Funktioniert Evapolar ohne Energieversorgung?',
  'faq.answer3': `
    Um Evapolar zu verwenden, schließen Sie es an eine Energieversorgung.
    Aber es muss keine Steckdose sein.
    Sie können Evapolar an eine Energiebank, den USB-Anschluss Ihres Autos
    oder Laptops oder eine Solarbatterie anschließen.
    Die einzige Bedingung ist, dass die Energieversorgung einen
    Ausgang von 5V und 2A haben muss.
  `,

  'faq.question4':
    'Wie viel reduziert Evapolar die Temperatur und welchen Größenbereich kann es abkühlen?',
  'faq.answer4': `
    Der maximale Temperaturabfall ist 59°F (15°C). Es ist erwähnenswert, dass die Temperaturänderung von äußeren Bedingungen und der Leistung des Geräts abhängt. In Regionen mit hoher Luftfeuchtigkeit kann der Unterschied bis zu 5 °C betragen, und in trockenen und heißen Regionen beträgt es bis zu 15°C. Wir haben das Gerät auf der ganzen Welt getestet und sind zu dem Schluss gekommen, dass die Wahrnehmung eines angenehmen Temperaturabfalls jedes Individuums wirklich variiert. Ihr eigener Körper wird immer ihr bester Indikator sein, da seine Bedürfnisse dramatisch variieren, abhängig von Ihrem Heimatklima und der jetzigen Jahreszeit.
  `,
  'faq.answer4.2': `
    Klassische Klimageräte beachten diesen Faktor nicht. Singapur kann ein anschauliches Beispiel sein: Sie können dort den nötigen Komfort mit einem Temperaturverlust von 5°C erreichen, während ein 50°F (10°C) Grad Abfall für Ihre Haut bereits unangenehm ist.
  `,
  'faq.answer4.3': `
    Die Evapolar Kühlzone beträgt 3-4 m2. Evapolar ist ein persönliches Gerät, das speziell für kleine Bereiche entwickelt wurde. Es wird Ihnen helfen, die bequemsten Bedingungen an Ihrem Arbeitsplatz oder Erholungsraum zu schaffen.
  `,

  'faq.question5':
    'Erfordert Evapolar zusätzliche Aufstellung oder Wartung? Was soll ich tun, wenn es nicht funktioniert?',
  'faq.answer5': `
    Evapolar ist sehr einfach zu verdienen und
    ist sofort einsatzbereit. Einfach füllen Sie den Wassertank
    und schließen das Gerät an. Der Wassertank muss
    jede 3-8 Stunden nachgefüllt werden.
    Weitere Details zur Verwendung von Evapolar finden Sie in der mitgelieferten Bedienungsanleitung.
    Insgesamt ist es nur wichtig, das Gerät nicht umzudrehen
    und die Operationskammer einmal pro Monat abzuwischen.
  `,
  'faq.answer5.2': `
    Im Falle einer Fehlfunktion, kontaktieren Sie unseren Unterstützungsdienst
    unter support@evapolar.com, oder Ihrer lokalen Händler
    (wenn Sie Ihr Gerät nicht auf unserer Web-Site gekauft haben).
  `,
};
