module.exports = {
  'privacy.title': `Политика конфиденциальности`,
  'privacy.text': `Посещая и/или используя наши сервисы, вы соглашаетесь с условиями, описанными в настоящей политике конфиденциальности. Если у вас есть вопросы по поводу настоящей политики конфиденциальности, свяжитесь с нами через форму`,
  'privacy.item1': `Evapolar собирает персональные данные (информацию, которая конкретно идентифицирует человека, например, его имя, адрес, номер мобильного телефона или адрес электронной почты, или другую информацию об этом человеке, которая напрямую связана с персональными данными) с целью обработки и доставки заказа пользователя, а также для выставления счетов и установления положений настоящих условий (включая, помимо прочего, положения, касающиеся гарантии, возвратов и обменов). 
Размещая заказ, пользователь соглашается предоставить Evapolar актуальную и точную информацию, необходимую для указанных выше целей.`,
  'privacy.item2': `Evapolar не передает персональные данные пользователя третьим лицам, за исключением тех, кто занимается обработкой и доставкой заказа пользователя, выставлением счетов и соблюдением положений настоящих условий.`,
  'privacy.item3': `Evapolar оставляет за собой право раскрывать персональные данные (1) в соответствии с требованиями закона и в тех случаях, когда Evapolar обоснованно полагает, что такое раскрытие необходимо для защиты прав Evapolar; (2) для соблюдения судебных разбирательств, постановлений суда или судебных процессов, проводимых Evapolar; (3) в связи с фактической или предполагаемой корпоративной сделкой или процедурой банкротства, затрагивающей все или часть бизнеса или актива, к которым относятся персональные данные.`,
  'privacy.item4': `Evapolar может также отслеживать определенные неличные данные, связанные с использованием пользователем веб-сайта, путем использования различных технологий (таких как файлы cookie, файлы журналов или веб-ошибок) для статистических, внутренних аналитических и исследовательских целей. Evapolar может передавать такие неличные данные в соответствии со своими бизнес-потребностями.`,
  'privacy.item5': `Политика конфиденциальности Evapolar распространяется только на информацию, которая собирается на веб-сайте и на hi.evapolar.com, и не распространяется на какие-либо веб-сайты, на которые Evapolar может ссылаться.`,
  'privacy.item6': `Evapolar может использовать электронную почту, предоставленную пользователем, для уведомлений, рекомендаций по безопасности, поддержке и обслуживанию, информационных бюллетеней, опросов, предложений и других рекламных материалов, связанных с продуктами или изменениями настоящих условий. Evapolar предоставляет пользователю право отказаться от использования личных данных компанией Evapolar для рекламных сообщений, отправив запрос через форму`,
  'privacy.link': `https://support.evapolar.com/hc/en-us/requests/new`,
  'privacy.item7': `Evapolar может получать комментарии и отзывы, относящиеся к продуктам или веб-сайту, от пользователей и размещать их на веб-сайте или в других источниках в рекламных целях и для других деловых нужд Evapolar. Для публикации такого контента Evapolar может идентифицировать авторов по их имени и фамилии, а также может указывать их родной город. Предоставляя такие комментарии и отзывы, пользователь дает Evapolar согласие на публикацию своего имени вместе с комментарием или отзывом.`,
  'privacy.item8': `Rakuten Advertising может собирать личную информацию, когда вы взаимодействуете с нашей цифровой собственностью, включая IP-адреса, цифровые идентификаторы, информацию о просмотре веб-страниц и использовании приложений, а также о том, как вы взаимодействуете с нашими ресурсами и рекламой для различных целей, таких как персонализация предложений и/или реклама и аналитика о том, как вы взаимодействуете с веб-сайтами или рекламой, а также в других коммерческих целях. Для получения дополнительной информации о сборе, использовании и передаче ваших личных данных и ваших правах воспользуйтесь ссылками ниже.\nRakuten Advertising\nПолитика конфиденциальности : <privacyPolicy>https://rakutenadvertising.com/legal-notices/services-privacy-policy/</privacyPolicy>\nПрава пользователя : <subjectRequests>https://rakutenadvertising.com/legal-notices/subject-requests/</subjectRequests>`,
};
