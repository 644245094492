module.exports = {
  'faq.title': `
    Got a question?
  `,

  'faq.text': `
   Read the answers to frequently asked
   questions or ask your own question.
  `,

  'faq.question1': 'How does Evapolar work?',
  'faq.answer1': `
    Evapolar is a portable personal device
    that not only cools but also humidifies and
    purifies the air around you. The operating
    principle is very simple: just fill the
    water tank and plug Evapolar in. 
    Within 2-3 minutes the Evapolar cartridge
    will absorb a huge amount of water.
    The water then spread evenly over the
    surface of the cooling pads. When the air
    circulates through the pads, the water evaporates,
    which makes the air cool down while becoming
    saturated with water. The built-in fan
    helps the cool air spread across an area
    of 45 sq ft (3-4 sq m) around you.
    The power control enables you to
    adjust the cooling effect, creating
    the most comfortable thermal conditions for you.
  `,

  'faq.question2':
    'What is the difference between Evapolar and other cooling devices?',
  'faq.answer2': `
    Evapolar surpasses other cooling devices in many aspects.
    First of all, Evapolar is a personal device.
    It can help you create your own most comfortable microclimate
    without invading the personal space of the people around you.
    Secondly, we call Evapolar an “air conditioner” rather than an
    “air cooler” due to its efficiency compared with “traditional”
    conditioners. Evapolar remains within the price range of budget-friendly
    tabletop devices, does not require any installation costs, and is
    ready to use right out of the box.
  `,
  'faq.answer2.2': `
    Thirdly, Evapolar cools the air by evaporating water,
    which also makes the device an efficient humidifier.
    You won’t have to buy a separate conditioner, humidifier
    or purifier. Our technology combines all the three functions
    into one device. This is how Evapolar creates a so-called
    “personal microclimate.” And last but not least,
    Evapolar uses 100 times less energy than standard split
    systems – only 10W – which will clearly have a positive
    effect on your electricity bill
  `,

  'faq.question3': 'Does Evapolar work without a power supply?',
  'faq.answer3': `
    To use Evapolar, it must be plugged into a power supply.
    But it doesn’t have to be a power socket.
    You can plug Evapolar into a power bank, the
    USB port of your car or laptop, or a solar battery.
    The only condition is that the power supply must have
    an output of 5V and 2A.
  `,

  'faq.question4':
    'How much does Evapolar reduce the temperature and what size area can it cool down?',
  'faq.answer4': `
    The maximum drop in temperature is 59°F (15°C).
    It is worth mentioning that the change in temperature depends on external
    conditions and the device’s power. In high-humidity regions,
    the difference may be as much as 41°F (5°C) degrees,
    in dry and hot ones it may get up to 59°F (15°C).
    We have tested the device all over the world and
    have come to the conclusion that each individual’s
    perception of a comfortable drop in temperature really varies.
    Your own body will always be your best indicator,
    since its needs vary dramatically depending on your
    native climate and the current season.
  `,
  'faq.answer4.2': `
    Classic air conditioners do not take this factor into account.
    Singapore may be a vivid example: you can achieve the necessary
    comfort there with a temperature loss of 41°F (5°C),
    and while a 50°F (10°C) degree drop is already unpleasant for your skin.
  `,
  'faq.answer4.3': `
    Evapolar’s cooling zone is 45 sq ft (3-4 sq m).
    Evapolar is a personal device developed specifically
    for small areas. It will help you create the most
    comfortable conditions at your workplace or recreational space.
  `,

  'faq.question5':
    'Does Evapolar require extra installation or maintenance? What should I do if it malfunctions?',
  'faq.answer5': `
    Evapolar is very easy to use and ready to work
    right out of the box. All you need to do is fill the
    water tank and plug in the device. The water tank needs
    to be refilled every 6-8 hours as the water evaporates.
    You can find more details on using Evapolar in the included manual.
    All in all, it is only important to keep the device from
    turning over and wipe the operating chamber once a month.
  `,
  'faq.answer5.2': `
    In case of a malfunction, you need to contact our support
    service at support@evapolar.com, or your local distributor
    (if you didn’t purchase your device on our website).
  `,
};
