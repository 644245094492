/**
 * @typedef {Object} ProductView
 * @property {number | string} id
 * @property {number | string} price
 */

/**
 * @param {string} eventName
 * @param {ProductView[]} productViews
 * @return {Object}
 */
export default function getDataLayerEventForItemsList(eventName, productViews) {
  return productViews.length === 1
    ? {
        event: 'gtagDynRem',
        dynRemEventName: eventName,
        dynRemValue: Number(productViews[0].price),
        dynRemFBItems: {
          id: Number(productViews[0].id),
        },
        dynRemItems: {
          id: Number(productViews[0].id),
          google_business_vertical: 'retail',
        },
      }
    : {
        event: 'gtagDynRem',
        dynRemEventName: eventName,
        dynRemValue: productViews.map((v) => Number(v.price)),
        dynRemFBItems: {
          id: productViews.map((v) => Number(v.id)),
        },
        dynRemItems: productViews.map((v) => ({
          id: Number(v.id),
          google_business_vertical: 'retail',
        })),
      };
}
