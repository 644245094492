/* eslint-disable import/prefer-default-export */

export const errorCodes = Object.freeze({
  DISCOUNT_OK: 'OK',
  DISCOUNT_NOT_FOUND: 'E1.1',
  DISCOUNT_EARLY: 'E2.2',
  DISCOUNT_EXPIRED: 'E2.3',
  DISCOUNT_NOT_AVAILABLE: 'E2.4',
  DISCOUNT_NO_USAGES: 'E2.5',
  DISCOUNT_MIN_ORDER: 'E2.6',
  DISCOUNT_VARIANTS: 'E2.7',
  DISCOUNT_UNKNOWN: 'E2.8',
});
