import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ActionType from '../store/action-type';
import isServerRender from '../utils/isServerRender';
import useBasket from './use-basket';

const useDiscountParams = () => {
  const { setPromoCode } = useBasket();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isServerRender()) return;

    const params = new URLSearchParams(window.location.search);
    const discount = params.get('discount');
    if (discount) {
      dispatch({
        type: ActionType.Basket.UpdateType,
        payload: { promoCodeEntered: discount },
      });
      setPromoCode(discount);
    }
  }, []);
};

export default useDiscountParams;
